import { createTheme } from '@mui/material/styles';
import { blue, grey, red, yellow } from '@mui/material/colors';

export const theme = createTheme({
    breakpoints: {
        values: {
            lg: 1280,
            md: 960,
            sm: 600,
            xl: 1920,
            xs: 0,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    color: 'white',
                    fontWeight: 700,
                    textTransform: 'none',
                },
            },
        },
        MuiButtonGroup: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
    },
    palette: {
        background: {
            default: '#F4F6F5',
        },
        colors: {
            blue: {
                main: blue['300'],
            },
            green: {
                contrastText: '#ffffff',
                main: '#00b588',
            },
            greenLight: {
                contrastText: '#00b588',
                main: '#d6f3ec',
            },
            grey: {
                main: '#6E7587',
            },
            greyLight: {
                contrastText: '#6e8780',
                main: grey['200'],
            },
            red: {
                contrastText: '#000000',
                main: '#f44336',
            },
            redLight: {
                contrastText: '#F91F44',
                main: '#FEDBE1',
            },
            yellow: {
                main: yellow['200'],
            },
        },
        error: {
            main: red.A400,
        },
        info: {
            main: grey['600'],
        },
        inverse: {
            contrastText: '#ffffff',
            main: '#000000',
        },
        primary: {
            main: '#00B579',
        },
        secondary: {
            main: '#556cd6',
        },
        success: {
            main: '#00B588',
        },
        warning: {
            main: '#ffc107',
        },
    },
    shape: {
        borderRadius: 8, // defaults to 4
    },
    typography: {
        button: {
            fontSize: '1rem',
        },
        fontFamily: ['Space Grotesk', 'sans-serif'].join(','),
        h1: {
            fontSize: '1.20rem',
            fontWeight: 700,
        },
    },
});
