import type { NonEmptyString, NonEmptyString16, NonEmptyString32, NonEmptyString64, NonEmptyString128, NonEmptyString255, NonEmptyString512, Integer, NonNegativeInteger, Uuid, Email, Phone, DomainWithLimitedSymbols, NonNegativeDecimalString, NonNegativeFloat, PositiveFloat, IntegerString, NonNegativeIntegerString, PositiveIntegerString, PositiveInteger, DateString, DecimalString, PreferredCurrency, DateTimeString, TimeString, TimeWithMillisecondsString, ZipCode, UserLanguage, Milliseconds, MillisecondsString, NfcSerialNumber, Iban, SwiftBic, TaxIdHungary, VATNumber, HttpUrl, JsonValue } from '../shared/types';
import type { payableDrivers, tableServiceDrivers, toBillOrderableDrivers, menuDrivers, toTableOrderableDrivers, byCustomerOrderableDrivers, byOperatorOrderableDrivers } from '../shared/enums';

export interface APIv1RestaurantAdmin {
    'ENDPOINT': {
        'GET:/api-key': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    id: string;
                    idRestaurant: Uuid;
                    created: Milliseconds;
                    name: NonEmptyString255;
                    deleted: Milliseconds | null;
                }>;
            };
        };
        'POST:/api-key': {
            input: {
                data: {
                    name: NonEmptyString255;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 201;
                result: {
                    id: string;
                    idRestaurant: Uuid;
                    created: Milliseconds;
                    name: NonEmptyString255;
                    deleted: Milliseconds | null;
                };
            };
        };
        'DELETE:/api-key/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/testing/require-auth-without-scopes': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                    restaurantId: Uuid;
                };
            };
        };
        'GET:/testing/require-auth-and-restaurant': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                    restaurantId: Uuid;
                };
            };
        };
        'GET:/testing/require-superadmin': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/testing/require-superadmin-two-scopes': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/testing/return-invalid-data': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/testing/throw-error': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/testing/throw-iip-error': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/testing/translations': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    message1: string;
                    message2: string;
                };
            };
        };
        'GET:/testing/throw-non-fatal-iip-error': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    restaurantUserId: Uuid;
                };
            };
        };
        'GET:/menu/menu-items': {
            input: {
                query: {
                    search?: NonEmptyString | undefined;
                    withModifier?: "true" | "false" | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    menuItems: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        description: NonEmptyString | null;
                        price: NonNegativeDecimalString;
                        tax: NonNegativeDecimalString;
                        allergens: Array<Integer>;
                        unit: RestaurantMenuItemUnit;
                        value: Integer | null;
                        internalName: NonEmptyString255 | null;
                        externalId: NonEmptyString255 | null;
                        byCustomer: boolean;
                        byOperator: boolean;
                        toTable: boolean;
                        toBill: boolean;
                        image: {
                            id: Uuid;
                            url?: NonEmptyString | undefined;
                        } | null;
                        type: MenuItemType;
                        modifiers: Array<{
                            id: Uuid;
                            name: NonEmptyString;
                            minSelected: NonNegativeInteger;
                            maxSelected: PositiveInteger | null;
                            maxCount: PositiveInteger | null;
                            externalId: NonEmptyString128 | null;
                        }>;
                        recommendedItem: boolean;
                    }>;
                };
            };
        };
        'POST:/menu/menu-item': {
            input: {
                data: {
                    id: Uuid;
                    name: NonEmptyString255;
                    description: NonEmptyString | null;
                    price: NonNegativeDecimalString;
                    externalId: NonEmptyString255 | null;
                    internalName: NonEmptyString255 | null;
                    allergens: Array<Integer>;
                    image: {
                        id: Uuid;
                    } | null;
                    value: Integer | null;
                    unit: RestaurantMenuItemUnit;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    type?: MenuItemType | undefined;
                    modifiers: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }>;
                    recommendedItem?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/menu/menu-item': {
            input: {
                data: {
                    id: Uuid;
                    name?: NonEmptyString255 | undefined;
                    description?: NonEmptyString | null | undefined;
                    price?: NonNegativeDecimalString | undefined;
                    externalId?: NonEmptyString255 | null | undefined;
                    internalName?: NonEmptyString255 | null | undefined;
                    allergens?: Array<Integer> | undefined;
                    image: {
                        id: Uuid;
                    } | null;
                    value?: Integer | null | undefined;
                    unit?: RestaurantMenuItemUnit | undefined;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    type?: MenuItemType | undefined;
                    modifiers: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }>;
                    recommendedItem?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/menu-item': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/publish-daily-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenu: {
                        id: Uuid;
                        restaurantId: Uuid;
                        date: DateString;
                        variant: RestaurantDailyMenuVariant;
                    };
                };
            };
        };
        'POST:/menu/cancel-daily-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/delete-daily-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/edit-daily-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenu: {
                        id: Uuid;
                        restaurantId: Uuid;
                        date: DateString;
                        variant: RestaurantDailyMenuVariant;
                    };
                };
            };
        };
        'GET:/menu/daily-menu-data-for-pdf': {
            input: {
                query: {
                    date: DateString;
                    restaurantId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenuData: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                        description: NonEmptyString | null;
                        openFrom: TimeString;
                        openTo: TimeString;
                        items: Array<{
                            id: Uuid;
                            name: NonEmptyString;
                            description: NonEmptyString | null;
                            price: NonNegativeDecimalString;
                            images: Array<NonEmptyString>;
                        }>;
                    }>;
                };
            };
        };
        'POST:/menu/export-daily-menu-to-pdf': {
            input: {
                data: {
                    date: DateString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: any;
            };
        };
        'POST:/menu/daily-menu-import-config': {
            input: {
                data: {
                    isDailyMenuSyncEnabled: boolean;
                    dailyMenuCategories: Array<{
                        name: NonEmptyString255;
                        id: NonEmptyString128;
                    }>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/daily-menu-import-config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    isDailyMenuSyncEnabled: boolean;
                    dailyMenuCategories: Array<{
                        name: NonEmptyString255;
                        id: NonEmptyString128;
                    }>;
                };
            };
        };
        'GET:/menu/daily-menu-categories': {
            input: {
                query: {
                    date: DateString;
                    variant?: RestaurantDailyMenuVariant | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenus: {
                        edit: {
                            id: Uuid;
                            restaurantId: Uuid;
                            date: DateString;
                            variant: RestaurantDailyMenuVariant;
                            dailyMenuCategories: Array<{
                                id: Uuid;
                                dailyMenuId: Uuid;
                                position: Integer;
                                name: NonEmptyString255;
                                description: NonEmptyString | null;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }>;
                        } | null;
                        prod: {
                            id: Uuid;
                            restaurantId: Uuid;
                            date: DateString;
                            variant: RestaurantDailyMenuVariant;
                            dailyMenuCategories: Array<{
                                id: Uuid;
                                dailyMenuId: Uuid;
                                position: Integer;
                                name: NonEmptyString255;
                                description: NonEmptyString | null;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }>;
                        } | null;
                    };
                };
            };
        };
        'GET:/menu/daily-menu-recommended-categories': {
            input: {
                query: {
                    date: DateString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    recommendedCategories: Array<{
                        name: NonEmptyString255;
                        description: NonEmptyString | null;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                };
            };
        };
        'POST:/menu/daily-menu-category': {
            input: {
                data: {
                    id: Uuid;
                    date: DateString;
                    name: NonEmptyString255;
                    description: NonEmptyString | null;
                    openFrom?: TimeString | undefined;
                    openTo?: TimeString | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/menu/daily-menu-category': {
            input: {
                data: {
                    id: Uuid;
                    name?: NonEmptyString255 | undefined;
                    description?: NonEmptyString | null | undefined;
                    position?: Integer | undefined;
                    openFrom?: TimeString | undefined;
                    openTo?: TimeString | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/daily-menu-category': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/daily-menu-items': {
            input: {
                query: {
                    dailyMenuCategoryId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenuItems: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        description: NonEmptyString | null;
                        price: NonNegativeDecimalString;
                        tax: NonNegativeDecimalString;
                        allergens: Array<Integer>;
                        unit: RestaurantMenuItemUnit;
                        value: Integer | null;
                        internalName: NonEmptyString255 | null;
                        externalId: NonEmptyString255 | null;
                        byCustomer: boolean;
                        byOperator: boolean;
                        toTable: boolean;
                        toBill: boolean;
                        imageId: Uuid | null;
                        image: {
                            id: Uuid;
                            url: NonEmptyString;
                            key: NonEmptyString;
                            name: NonEmptyString;
                            mimetype: NonEmptyString | null;
                            size: Integer;
                            createdAt: Date;
                        } | null;
                        type: MenuItemType;
                        modifiers: Array<{
                            id: Uuid;
                            name: NonEmptyString;
                            minSelected: NonNegativeInteger;
                            maxSelected: PositiveInteger | null;
                            maxCount: PositiveInteger | null;
                            externalId: NonEmptyString128 | null;
                        }>;
                        menuItemId: Uuid | null;
                        dailyMenuCategoryId: Uuid;
                        position?: Integer | undefined;
                    }>;
                };
            };
        };
        'POST:/menu/create-daily-menu-item-from-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    dailyMenuCategoryId: Uuid;
                    menuItemId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/daily-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    dailyMenuCategoryId: Uuid;
                    name: NonEmptyString255;
                    description: NonEmptyString | null;
                    price: NonNegativeDecimalString;
                    externalId: NonEmptyString255 | null;
                    internalName: NonEmptyString255 | null;
                    allergens: Array<Integer>;
                    image: {
                        id: Uuid;
                    } | null;
                    value: Integer | null;
                    unit: RestaurantMenuItemUnit;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    modifiers: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/menu/daily-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    name?: NonEmptyString255 | undefined;
                    description?: NonEmptyString | null | undefined;
                    price?: NonNegativeDecimalString | undefined;
                    position?: Integer | undefined;
                    externalId?: NonEmptyString255 | null | undefined;
                    internalName?: NonEmptyString255 | null | undefined;
                    allergens?: Array<Integer> | undefined;
                    image?: {
                        id: Uuid;
                    } | null | undefined;
                    value?: Integer | null | undefined;
                    unit?: RestaurantMenuItemUnit | undefined;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    modifiers?: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }> | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/daily-menu-item': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/publish-permanent-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    permanentMenu: {
                        id: Uuid;
                        restaurantId: Uuid;
                        variant: RestaurantPermanentMenuVariant;
                    };
                };
            };
        };
        'POST:/menu/cancel-permanent-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/delete-permanent-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/edit-permanent-menu': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    permanentMenu: {
                        id: Uuid;
                        restaurantId: Uuid;
                        variant: RestaurantPermanentMenuVariant;
                    };
                };
            };
        };
        'GET:/menu/permanent-menu-categories': {
            input: {
                query: {
                    variant?: RestaurantPermanentMenuVariant | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    permanentMenus: {
                        edit: {
                            id: Uuid;
                            restaurantId: Uuid;
                            variant: RestaurantPermanentMenuVariant;
                            permanentMenuCategories: Array<{
                                id: Uuid;
                                permanentMenuId: Uuid;
                                position: Integer;
                                name: NonEmptyString;
                                description: NonEmptyString | null;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }>;
                        } | null;
                        prod: {
                            id: Uuid;
                            restaurantId: Uuid;
                            variant: RestaurantPermanentMenuVariant;
                            permanentMenuCategories: Array<{
                                id: Uuid;
                                permanentMenuId: Uuid;
                                position: Integer;
                                name: NonEmptyString;
                                description: NonEmptyString | null;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }>;
                        } | null;
                    };
                };
            };
        };
        'GET:/menu/permanent-menu-recommended-categories': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    recommendedCategories: Array<{
                        name: NonEmptyString;
                        description: NonEmptyString | null;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                };
            };
        };
        'POST:/menu/permanent-menu-category': {
            input: {
                data: {
                    id: Uuid;
                    name: NonEmptyString;
                    description: NonEmptyString | null;
                    openFrom?: TimeString | undefined;
                    openTo?: TimeString | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/menu/permanent-menu-category': {
            input: {
                data: {
                    description?: NonEmptyString | null | undefined;
                    id: Uuid;
                    name?: NonEmptyString | undefined;
                    openFrom?: TimeString | undefined;
                    openTo?: TimeString | undefined;
                    position?: Integer | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/permanent-menu-category': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/permanent-menu-items': {
            input: {
                query: {
                    permanentMenuCategoryId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    permanentMenuItems: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        description: NonEmptyString | null;
                        price: NonNegativeDecimalString;
                        tax: NonNegativeDecimalString;
                        allergens: Array<Integer>;
                        unit: RestaurantMenuItemUnit;
                        value: Integer | null;
                        internalName: NonEmptyString255 | null;
                        externalId: NonEmptyString255 | null;
                        byCustomer: boolean;
                        byOperator: boolean;
                        toTable: boolean;
                        toBill: boolean;
                        imageId: Uuid | null;
                        image: {
                            id: Uuid;
                            url: NonEmptyString;
                            key: NonEmptyString;
                            name: NonEmptyString;
                            mimetype: NonEmptyString | null;
                            size: Integer;
                            createdAt: Date;
                        } | null;
                        type: MenuItemType;
                        modifiers: Array<{
                            id: Uuid;
                            name: NonEmptyString;
                            minSelected: NonNegativeInteger;
                            maxSelected: PositiveInteger | null;
                            maxCount: PositiveInteger | null;
                            externalId: NonEmptyString128 | null;
                        }>;
                        menuItemId: Uuid | null;
                        permanentMenuCategoryId: Uuid;
                        position?: Integer | undefined;
                    }>;
                };
            };
        };
        'POST:/menu/create-permanent-menu-item-from-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    permanentMenuCategoryId: Uuid;
                    menuItemId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/permanent-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    permanentMenuCategoryId: Uuid;
                    name: NonEmptyString255;
                    description: NonEmptyString | null;
                    price: NonNegativeDecimalString;
                    externalId: NonEmptyString255 | null;
                    internalName: NonEmptyString255 | null;
                    allergens: Array<Integer>;
                    image: {
                        id: Uuid;
                    } | null;
                    value: Integer | null;
                    unit: RestaurantMenuItemUnit;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    modifiers: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/menu/permanent-menu-item': {
            input: {
                data: {
                    id: Uuid;
                    name?: NonEmptyString255 | undefined;
                    description?: NonEmptyString | null | undefined;
                    price?: NonNegativeDecimalString | undefined;
                    position?: Integer | undefined;
                    externalId?: NonEmptyString255 | null | undefined;
                    internalName?: NonEmptyString255 | null | undefined;
                    allergens?: Array<Integer> | undefined;
                    image?: {
                        id: Uuid;
                    } | null | undefined;
                    value?: Integer | null | undefined;
                    unit?: RestaurantMenuItemUnit | undefined;
                    byCustomer?: boolean | undefined;
                    byOperator?: boolean | undefined;
                    toTable?: boolean | undefined;
                    toBill?: boolean | undefined;
                    tax?: NonNegativeDecimalString | undefined;
                    modifiers?: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                    }> | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/permanent-menu-item': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/menu-pauses': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    menuPauses: Array<{
                        id: Uuid;
                        restaurantId: Uuid;
                        from: Date | null;
                        to: Date;
                    }>;
                };
            };
        };
        'POST:/menu/menu-pause': {
            input: {
                data: {
                    id: Uuid;
                    from?: NonEmptyString | null | undefined;
                    to: NonEmptyString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/menu/menu-pause': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/modifiers': {
            input: {
                query: {
                    search?: NonEmptyString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    menuModifiers: Array<{
                        id: Uuid;
                        name: NonEmptyString;
                        minSelected: NonNegativeInteger;
                        maxSelected: PositiveInteger | null;
                        maxCount: PositiveInteger | null;
                        externalId: NonEmptyString128 | null;
                        numberOfOptions: NonNegativeInteger;
                    }>;
                };
            };
        };
        'DELETE:/menu/modifiers/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/menu/modifiers': {
            input: {
                data: {
                    id?: Uuid | null | undefined;
                    name: NonEmptyString;
                    minSelected: NonNegativeInteger;
                    maxSelected: PositiveInteger | null;
                    maxCount: PositiveInteger | null;
                    externalId?: NonEmptyString128 | null | undefined;
                    options: Array<{
                        restaurantMenuItemId: Uuid;
                        addPrice: number;
                        maxCount: PositiveInteger | null;
                        position: NonNegativeInteger;
                        defaultSelected: NonNegativeInteger;
                        externalId?: NonEmptyString128 | null | undefined;
                    }>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    name: NonEmptyString;
                    minSelected: NonNegativeInteger;
                    maxSelected: PositiveInteger | null;
                    maxCount: PositiveInteger | null;
                    externalId: NonEmptyString128 | null;
                    options: Array<{
                        restaurantMenuItemId: Uuid;
                        addPrice: number;
                        maxCount: PositiveInteger | null;
                        position: NonNegativeInteger;
                        defaultSelected: NonNegativeInteger;
                        externalId: NonEmptyString128 | null;
                    }>;
                };
            };
        };
        'GET:/menu/modifiers/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    modifier: {
                        id: Uuid;
                        name: NonEmptyString;
                        minSelected: NonNegativeInteger;
                        maxSelected: PositiveInteger | null;
                        maxCount: PositiveInteger | null;
                        externalId: NonEmptyString128 | null;
                        options: Array<{
                            restaurantMenuItemId: Uuid;
                            addPrice: number;
                            maxCount: PositiveInteger | null;
                            position: NonNegativeInteger;
                            defaultSelected: NonNegativeInteger;
                            externalId: NonEmptyString128 | null;
                        }>;
                    };
                    menuItems: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        description: NonEmptyString | null;
                        price: NonNegativeDecimalString;
                        tax: NonNegativeDecimalString;
                        allergens: Array<Integer>;
                        unit: RestaurantMenuItemUnit;
                        value: Integer | null;
                        internalName: NonEmptyString255 | null;
                        externalId: NonEmptyString255 | null;
                        byCustomer: boolean;
                        byOperator: boolean;
                        toTable: boolean;
                        toBill: boolean;
                        image: {
                            id: Uuid;
                            url?: NonEmptyString | undefined;
                        } | null;
                        type: MenuItemType;
                        modifiers: Array<{
                            id: Uuid;
                            name: NonEmptyString;
                            minSelected: NonNegativeInteger;
                            maxSelected: PositiveInteger | null;
                            maxCount: PositiveInteger | null;
                            externalId: NonEmptyString128 | null;
                        }>;
                        recommendedItem: boolean;
                    }>;
                };
            };
        };
        'GET:/mia-eshop-config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    isEnabled: boolean;
                    isOwnDelivery: boolean;
                    isPosShopcast: boolean;
                    minimalPriceForDelivery: NonNegativeDecimalString;
                    minimumPaymentsPerMonthCurrently: number;
                    minimumPaymentsPerMonthNewly: number;
                    pricePerMonthCurrently: number;
                    priceWithEshopPerMonthNewly: number;
                    selfDeliveryPrice: NonNegativeDecimalString;
                    shopUrl?: string | undefined | null;
                    transportIdFromPos: NonEmptyString255 | null;
                };
            };
        };
        'POST:/super/mia-eshop-config': {
            input: {
                data: {
                    enabled: boolean;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 204;
                    result: {
                        shopUrl: string | null;
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 500;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/mia-eshop-config': {
            input: {
                data: {
                    enabled: boolean;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 500;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'PUT:/mia-eshop-config': {
            input: {
                data: {
                    isOwnDelivery: boolean;
                    minimalPriceForDelivery: NonNegativeDecimalString;
                    selfDeliveryPrice: NonNegativeDecimalString;
                    transportIdFromPos: NonEmptyString255 | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/retrieve-admin-url-for-eshop': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        url: NonEmptyString;
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/payment/filter': {
            input: {
                query: {
                    dayFrom: DateString;
                    dayTo: DateString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    methods: Array<{
                        id: string;
                        name: string;
                    }>;
                    tables: Array<{
                        tableName: string;
                    }>;
                    waiters: Array<{
                        cId: NonEmptyString255;
                        name: NonEmptyString255;
                    }>;
                };
            };
        };
        'GET:/payment/:id/pdf': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: any;
            };
        };
        'GET:/payment': {
            input: {
                query: {
                    dayFrom: DateString;
                    dayTo: DateString;
                    filter?: Record<string, string> | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result?: any;
            };
        };
        'GET:/feedback-tags': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    categories: Array<{
                        tags: Array<{
                            categoryId: Uuid;
                            id: NonEmptyString;
                            positive: boolean;
                            text: NonEmptyString255;
                            threshold: Integer;
                        }>;
                        id: Uuid;
                        name: NonEmptyString255;
                    }>;
                };
            };
        };
        'GET:/feedback': {
            input: {
                query: (
                    {
                        selectedRestaurantId?: Uuid | undefined;
                    } & (
                        {
                            from: IntegerString;
                            to: IntegerString;
                        } | {
                            dayFrom: DateString;
                            dayTo: DateString;
                        }
                    )
                );
            };
            result: {
                httpStatus: 200;
                result: {
                    feedbacks: Array<{
                        currency: Currency;
                        feedback: string;
                        idPayment: Integer;
                        idRestaurant: Uuid | null;
                        items: Array<{
                            additionalData?: JsonValue | undefined;
                            id: string | null;
                            minQuantity: string;
                            name: string;
                            price: string;
                            quantity: string;
                            tags: Array<string>;
                            subItems?: Array<{
                                id: string;
                                name: string;
                                price: string;
                            }> | undefined;
                        }>;
                        idUser: Uuid | null;
                        pinnedFeedback: boolean;
                        rate: Integer;
                        rateAmbient: Integer | null;
                        rateFood: Integer | null;
                        rateService: Integer | null;
                        tags: Array<{
                            categoryId: Uuid;
                            id: NonEmptyString;
                            positive: boolean;
                            text: NonEmptyString255;
                            threshold: Integer;
                        }>;
                        timestamp: number;
                        totalAmount: number;
                        user?: {
                            id: Uuid;
                            name: string;
                            surname: string;
                        } | undefined;
                    }>;
                };
            };
        };
        'PUT:/feedback/pin': {
            input: {
                data: {
                    paymentId: Integer;
                    pinnedFeedback: boolean;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/table': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        availablePaymentInAdvance: boolean;
                        availableStaticTables: boolean;
                        cloudId: NonEmptyString255 | null;
                        tables: Array<{
                            area: NonEmptyString255 | null;
                            forcePaymentInAdvance: boolean;
                            isOrderingEnabled: boolean;
                            id: NonEmptyString255;
                            isStatic: boolean;
                            name: NonEmptyString255 | null;
                            store: NonEmptyString255 | null;
                        }>;
                    };
                } | {
                    httpStatus: 201;
                    result: {
                        isNotActivated: true;
                        message: NonEmptyString;
                    };
                }
            );
        };
        'GET:/table/content': {
            input: {
                query: {
                    tableId: NonEmptyString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    bills: Array<{
                        currency: Currency;
                        id: NonEmptyString;
                        items: Array<{
                            name: string;
                            price: DecimalString;
                            quantity: DecimalString;
                        }>;
                    }>;
                };
            };
        };
        'DELETE:/table/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'POST:/table': {
            input: {
                data: {
                    forcePaymentInAdvance?: boolean | undefined;
                    id: NonEmptyString255;
                    isStatic?: boolean | undefined;
                    isOrderingEnabled?: boolean | undefined;
                    name: NonEmptyString255;
                    store: NonEmptyString255 | null;
                    area: NonEmptyString255 | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/notifications/for-user': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    userNotifications: Array<{
                        notification: (
                            {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "reservationCreated";
                                data: {
                                    restaurant: {
                                        id: Uuid;
                                        name: NonEmptyString;
                                    };
                                    reservation: {
                                        id: Uuid;
                                        reservationTime: DateTimeString;
                                        seats: PositiveInteger;
                                        user: {
                                            id: Uuid | null;
                                            email: Email;
                                            phone: Phone;
                                            name: NonEmptyString;
                                            note: NonEmptyString | null;
                                        };
                                    };
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "reservationCanceled";
                                data: {
                                    restaurant: {
                                        id: Uuid;
                                        name: NonEmptyString;
                                    };
                                    reservation: {
                                        id: Uuid;
                                        reservationTime: DateTimeString;
                                        seats: PositiveInteger;
                                        user: {
                                            id: Uuid | null;
                                            email: Email;
                                            phone: Phone;
                                            name: NonEmptyString;
                                            note: NonEmptyString | null;
                                        };
                                    };
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "reservationConfirmed";
                                data: {
                                    restaurant: {
                                        id: Uuid;
                                        name: NonEmptyString;
                                    };
                                    reservation: {
                                        id: Uuid;
                                        reservationTime: DateTimeString;
                                        seats: PositiveInteger;
                                        user: {
                                            id: Uuid | null;
                                            email: Email;
                                            phone: Phone;
                                            name: NonEmptyString;
                                            note: NonEmptyString | null;
                                        };
                                    };
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "message";
                                data: {
                                    message: NonEmptyString;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "loyaltyOfferCreated";
                                data: {
                                    restaurantName: NonEmptyString;
                                    loyaltyOfferTitle: NonEmptyString;
                                    restaurantUserName: NonEmptyString;
                                    loyaltyOfferUrl: NonEmptyString;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "newQerkoFeature";
                                data: {
                                    blocks: Array<{
                                        type: "title" | "p" | "html";
                                        content: NonEmptyString;
                                    }>;
                                    imageUrl: NonEmptyString;
                                    targetUrl: NonEmptyString;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "posInvalidApiKey";
                                data: {
                                    restaurant: {
                                        id: Uuid;
                                        name: NonEmptyString;
                                    };
                                    apiKey: NonEmptyString;
                                    apiKeyName: NonEmptyString;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "posError";
                                data: {
                                    restaurantId: Uuid;
                                    posCode?: NonEmptyString | undefined;
                                    message: NonEmptyString;
                                    posName: AllAvailableDrivers;
                                    requestData?: NonEmptyString | undefined;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "partnerCampaignNearToLimit";
                                data: {
                                    restaurantId: Uuid;
                                    remainingVouchers: number;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            } | {
                                id: Uuid;
                                restaurantId: Uuid | null;
                                groupId: NonEmptyString | null;
                                createdAt: Date;
                                expireAt: Date | null;
                                type: "partnerCampaignReachedLimit";
                                data: {
                                    restaurantId: Uuid;
                                    remainingVouchers: number;
                                };
                                resolvedAt: Date | null;
                                resolvedByAction: RestaurantUserNotificationAction | null;
                            }
                        );
                        id: Uuid;
                        notificationId: Uuid;
                        userId: Uuid;
                        readAt: Date | null;
                        hasPopup: boolean;
                    }>;
                };
            };
        };
        'GET:/notifications/count-unread-for-user': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    numberOfUnreadUserNotifications: number;
                    numberOfUnreadUserPopups: number;
                };
            };
        };
        'POST:/notifications/call-action': {
            input: {
                data: {
                    restaurantUserNotificationId: Uuid;
                    action: RestaurantUserNotificationAction;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/notifications/mark-as-read': {
            input: {
                data: {
                    restaurantUserNotificationId: Uuid;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result?: undefined;
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/notifications/mark-all-as-read': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/notifications/subscriptions': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    userSubscriptions: Record<RestaurantUserNotificationType, Array<NotificationSubscriptionType>>;
                };
            };
        };
        'POST:/notifications/update-subscriptions': {
            input: {
                data: {
                    userSubscriptions: Record<RestaurantUserNotificationType, Array<NotificationSubscriptionType> | null | undefined>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/notifications/subscriptions-by-notification-group': {
            input: {
                query: {
                    notificationGroup: unknown;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    subscriptionsByGroup: {
                        notificationGroup: unknown;
                        subscriptions: Array<{
                            user: {
                                id: Uuid;
                                name: NonEmptyString;
                                email: Email | null;
                                phone: Phone | null;
                            };
                            subscriptionTypes: Array<NotificationSubscriptionType> | null;
                        }>;
                    };
                };
            };
        };
        'POST:/notifications/subscriptions-by-notification-group': {
            input: {
                data: {
                    notificationGroup: unknown;
                    subscriptions: Array<{
                        user: (
                            {
                                id: Uuid;
                            } | {
                                id?: Uuid | undefined;
                                name: NonEmptyString;
                                email: Email | null;
                                phone: Phone | null;
                            }
                        );
                        subscriptionTypes: Array<NotificationSubscriptionType> | null;
                    }>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/login': {
            input: {
                data: {
                    username: NonEmptyString255;
                    password: NonEmptyString;
                    passwordIsSha512?: boolean | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    accessToken: NonEmptyString;
                    name: NonEmptyString;
                    surname: string;
                    email: Email | null;
                    phone: Phone | null;
                    username: NonEmptyString;
                    emailVerified: boolean;
                    isPhoneVerified: boolean;
                    isManaged: boolean;
                    pipeDriveUserId: Integer | null;
                    superadmin: (
                        0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                    );
                    language: Language | null;
                };
            };
        };
        'DELETE:/login': {
            input: Record<string, never>;
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/dashboard/statistics/payments': {
            input: {
                query: {
                    dayFrom: DateString;
                    dayTo: DateString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    paymentsGroupedByHours: Array<{
                        countOfPayments: NonNegativeInteger;
                        sumTotalAmount: NonNegativeDecimalString;
                        sumSaleAmount: NonNegativeDecimalString;
                        sumTipForRestaurant: NonNegativeDecimalString;
                        hour: NonEmptyString16;
                    }>;
                    totalTipForRestaurant: NonNegativeDecimalString;
                    totalAmountForRestaurant: NonNegativeDecimalString;
                    totalSaleForRestaurant: NonNegativeDecimalString;
                    percentTipForRestaurant: string;
                    currency: Currency;
                };
            };
        };
        'GET:/dashboard/payments': {
            input: {
                query: {
                    from: IntegerString;
                    to: IntegerString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    currency: Currency;
                    timestamp: number;
                    tip: number;
                    tipFromCustomer: number;
                    totalAmount: number;
                }>;
            };
        };
        'GET:/dashboard/feedbacks': {
            input: {
                query: {
                    from: IntegerString;
                    to: IntegerString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    rate: number | null;
                };
            };
        };
        'GET:/dashboard/flags': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    shouldDisplayCsobBanner: boolean;
                };
            };
        };
        'POST:/dashboard/refresh-menu': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/customers/unique-customers': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    date: DateString;
                    idUser: Uuid;
                    timestamp: number;
                }>;
            };
        };
        'GET:/customers/customers-stats': {
            input: {
                query: (
                    {
                        selectedRestaurantId?: Uuid | undefined;
                    } & (
                        {
                            from: IntegerString;
                            to: IntegerString;
                        } | {
                            dayFrom: DateString;
                            dayTo: DateString;
                        }
                    )
                );
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    date: DateString;
                    idUser: Uuid;
                    timestamp: number;
                    name: string;
                    age: number | null;
                }>;
            };
        };
        'GET:/customers/rating': {
            input: {
                query: (
                    {
                        selectedRestaurantId?: Uuid | undefined;
                    } & (
                        {
                            from: IntegerString;
                            to: IntegerString;
                        } | {
                            dayFrom: DateString;
                            dayTo: DateString;
                        }
                    )
                );
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    rate: number;
                    timestamp: number;
                }>;
            };
        };
        'POST:/customers/receipts': {
            input: {
                data: {
                    users: Array<Uuid>;
                };
                query: {
                    from: IntegerString;
                    to: IntegerString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result?: any;
            };
        };
        'GET:/config': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    isSandbox: boolean;
                };
            };
        };
        'GET:/countries': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    countries: Array<{
                        alpha3Code: string;
                        currency: string;
                        id: string;
                        name: string;
                        phone: string;
                        regions: Array<{
                            id: Uuid;
                            label: NonEmptyString;
                        }>;
                        timezones: Array<string>;
                    }>;
                };
            };
        };
        'GET:/loyalty/global-offers-edit/:offerId': {
            input: {
                params: {
                    offerId: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        id?: Uuid | undefined;
                        type?: LoyaltyOfferType | undefined;
                        applyTo?: ApplyToType | undefined;
                        isOpenForNewUsers?: boolean | undefined;
                        isRepeatable?: boolean | undefined;
                        minTimeBetweenUpdates?: Milliseconds | undefined;
                        progressExpiration?: Milliseconds | undefined;
                        voucherExpiration?: Milliseconds | undefined;
                        progressMax?: NonNegativeFloat | undefined;
                        minAmount?: NonNegativeFloat | undefined;
                        startAt?: Date | null | undefined;
                        endAt?: Date | null | undefined;
                        isVisibleForRestaurant?: boolean | undefined;
                        voucherLimit?: NonNegativeInteger | null | undefined;
                        promoName?: NonEmptyString64 | null | undefined;
                        cardBrand?: CardBrand | null | undefined;
                        country?: Country | null | undefined;
                        businessType?: BusinessType | null | undefined;
                    } | null;
                };
            };
        };
        'PUT:/loyalty/global-offers-edit/:offerId': {
            input: {
                data: {
                    id?: Uuid | undefined;
                    type?: LoyaltyOfferType | undefined;
                    applyTo?: ApplyToType | undefined;
                    isOpenForNewUsers?: boolean | undefined;
                    isRepeatable?: boolean | undefined;
                    minTimeBetweenUpdates?: Milliseconds | undefined;
                    progressExpiration?: Milliseconds | undefined;
                    voucherExpiration?: Milliseconds | undefined;
                    progressMax?: NonNegativeFloat | undefined;
                    minAmount?: NonNegativeFloat | undefined;
                    startAt?: Date | null | undefined;
                    endAt?: Date | null | undefined;
                    isVisibleForRestaurant?: boolean | undefined;
                    voucherLimit?: NonNegativeInteger | null | undefined;
                    promoName?: NonEmptyString64 | null | undefined;
                    cardBrand?: CardBrand | null | undefined;
                    country?: Country | null | undefined;
                    businessType?: BusinessType | null | undefined;
                };
                params: {
                    offerId: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/loyalty/global-vouchers': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    offerId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        userId: Uuid;
                        type: LoyaltyOfferType;
                        applyTo: ApplyToType;
                        isRepeatable: boolean;
                        isShareable: boolean;
                        acquired: DateString;
                        validTo: DateString;
                        consumedByPaymentId: Integer | null;
                        user: {
                            name: NonEmptyString255;
                            surname: NonEmptyString255;
                            email: Email | null;
                            thirdPartyEmail: Email | null;
                        };
                    }>;
                    primaryKey: "userId";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/loyalty/global-progresses': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    offerId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        userId: Uuid;
                        current: NonNegativeFloat;
                        max: NonNegativeFloat;
                        started: DateString;
                        validTo: DateString | null;
                        lastUpdate: Integer;
                        user: {
                            name: NonEmptyString255;
                            surname: NonEmptyString255;
                            email: Email | null;
                            thirdPartyEmail: Email | null;
                        };
                    }>;
                    primaryKey: "userId";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/loyalty/global-offers': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        type: LoyaltyOfferType;
                        applyTo: ApplyToType;
                        isOpenForNewUsers: boolean;
                        isRepeatable: boolean;
                        minTimeBetweenUpdates: Milliseconds;
                        progressExpiration: Milliseconds;
                        voucherExpiration: Milliseconds;
                        progressMax: NonNegativeFloat;
                        minAmount: NonNegativeFloat;
                        startAt: Date | null;
                        endAt: Date | null;
                        isVisibleForRestaurant: boolean;
                        voucherLimit: NonNegativeInteger | null;
                        usedProgress: NonNegativeInteger | null;
                        promoName: NonEmptyString64 | null;
                        cardBrand: CardBrand | null;
                        country: Country | null;
                        businessType: BusinessType | null;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/loyalty': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    references: Array<{
                        applyTo: ApplyToType;
                        bonusOffer: {
                            categoryName?: NonEmptyString | undefined;
                            gender?: string | undefined;
                            items?: Array<{
                                name: NonEmptyString;
                                quantity: PositiveFloat;
                            }> | undefined;
                            maxValue: PositiveInteger | null;
                            type: BonusOfferType;
                            value: PositiveFloat;
                            currency?: Currency | undefined;
                        };
                        isRepeatable: boolean;
                        progressExpiration: Milliseconds;
                        progressMax: NonNegativeFloat;
                        type: LoyaltyOfferType;
                        minAmount: NonNegativeFloat;
                        voucherExpiration: Milliseconds;
                        restaurants: Array<Uuid>;
                        isVisibleForRestaurant: boolean;
                        voucherLimit: NonNegativeInteger | null;
                        promoName: NonEmptyString64 | null;
                        cardBrand: CardBrand | null;
                        description?: NonEmptyString | undefined;
                        voucherDescription: NonEmptyString;
                        voucherTitle: NonEmptyString;
                        voucherIcon: {
                            id: Uuid;
                            url: NonEmptyString;
                        } | null;
                    }>;
                    active: Array<{
                        applyTo: ApplyToType;
                        bonusOffer: {
                            categoryName?: NonEmptyString | undefined;
                            gender?: string | undefined;
                            items?: Array<{
                                name: NonEmptyString;
                                quantity: PositiveFloat;
                            }> | undefined;
                            maxValue: PositiveInteger | null;
                            type: BonusOfferType;
                            value: PositiveFloat;
                            currency?: Currency | undefined;
                        };
                        isRepeatable: boolean;
                        progressExpiration: Milliseconds;
                        progressMax: NonNegativeFloat;
                        type: LoyaltyOfferType;
                        minAmount: NonNegativeFloat;
                        voucherExpiration: Milliseconds;
                        restaurants: Array<Uuid>;
                        isVisibleForRestaurant: boolean;
                        voucherLimit: NonNegativeInteger | null;
                        promoName: NonEmptyString64 | null;
                        cardBrand: CardBrand | null;
                        description?: NonEmptyString | undefined;
                        voucherDescription: NonEmptyString;
                        voucherTitle: NonEmptyString;
                        voucherIcon: {
                            id: Uuid;
                            url: NonEmptyString;
                        } | null;
                        isOpenForNewUsers: boolean;
                        endAt: Date | null;
                        startAt: Date | null;
                        id: Uuid;
                        minTimeBetweenUpdates: Milliseconds;
                        usedProgress: NonNegativeInteger;
                        progressCount: number;
                        voucherCount: number;
                        appliedVoucherCount: number;
                    }>;
                };
            };
        };
        'GET:/loyalty/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    applyTo: ApplyToType;
                    bonusOffer: {
                        categoryName?: NonEmptyString | undefined;
                        gender?: string | undefined;
                        items?: Array<{
                            name: NonEmptyString;
                            quantity: PositiveFloat;
                        }> | undefined;
                        maxValue: PositiveInteger | null;
                        type: BonusOfferType;
                        value: PositiveFloat;
                        currency?: Currency | undefined;
                    };
                    isRepeatable: boolean;
                    progressExpiration: Milliseconds;
                    progressMax: NonNegativeFloat;
                    type: LoyaltyOfferType;
                    minAmount: NonNegativeFloat;
                    voucherExpiration: Milliseconds;
                    restaurants: Array<Uuid>;
                    isVisibleForRestaurant: boolean;
                    voucherLimit: NonNegativeInteger | null;
                    promoName: NonEmptyString64 | null;
                    cardBrand: CardBrand | null;
                    description?: NonEmptyString | undefined;
                    voucherDescription: NonEmptyString;
                    voucherTitle: NonEmptyString;
                    voucherIcon: {
                        id: Uuid;
                        url: NonEmptyString;
                    } | null;
                    isOpenForNewUsers: boolean;
                    endAt: Date | null;
                    startAt: Date | null;
                    id: Uuid;
                    minTimeBetweenUpdates: Milliseconds;
                    usedProgress: NonNegativeInteger;
                };
            };
        };
        'DELETE:/loyalty/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'PUT:/loyalty': {
            input: {
                data: {
                    bonusOffer: {
                        items?: Array<{
                            name: NonEmptyString;
                            quantity: PositiveFloat;
                        }> | undefined;
                        maxValue: PositiveInteger | null;
                    };
                    isRepeatable: boolean;
                    progressExpiration: Milliseconds;
                    minAmount: NonNegativeFloat;
                    voucherExpiration: Milliseconds;
                    restaurants: Array<Uuid>;
                    isVisibleForRestaurant?: boolean | undefined;
                    voucherLimit?: NonNegativeInteger | null | undefined;
                    promoName?: NonEmptyString64 | null | undefined;
                    cardBrand?: CardBrand | null | undefined;
                    voucherIconId: Uuid | null;
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/loyalty': {
            input: {
                data: {
                    applyTo: ApplyToType;
                    bonusOffer: {
                        categoryName?: NonEmptyString | undefined;
                        gender?: string | undefined;
                        items?: Array<{
                            name: NonEmptyString;
                            quantity: PositiveFloat;
                        }> | undefined;
                        maxValue: PositiveInteger | null;
                        type: BonusOfferType;
                        value: PositiveFloat;
                        currency?: Currency | undefined;
                    };
                    isRepeatable: boolean;
                    progressExpiration: Milliseconds;
                    progressMax: NonNegativeFloat;
                    type: LoyaltyOfferType;
                    minAmount: NonNegativeFloat;
                    voucherExpiration: Milliseconds;
                    restaurants: Array<Uuid>;
                    isVisibleForRestaurant?: boolean | undefined;
                    voucherLimit?: NonNegativeInteger | null | undefined;
                    promoName?: NonEmptyString64 | null | undefined;
                    cardBrand?: CardBrand | null | undefined;
                    voucherIconId: Uuid | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 401;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/qr-image/:code': {
            input: {
                query: {
                    scale?: DecimalString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
                params: {
                    code: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: any;
            };
        };
        'GET:/qr': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    cIdTable: NonEmptyString255 | null;
                    id: NonEmptyString255;
                    idRestaurant: Uuid;
                    mode: TableTagMode;
                    namespace: TableTagNamespace;
                }>;
            };
        };
        'POST:/qr': {
            input: {
                data: Array<{
                    cArea: NonEmptyString255 | null;
                    cIdTable: NonEmptyString255;
                    cNameTable: NonEmptyString255 | null;
                    cStore: NonEmptyString255 | null;
                    idQr: NonEmptyString;
                }>;
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'DELETE:/qr': {
            input: {
                data: Array<NonEmptyString>;
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/users': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: Array<(
                    {
                        email: Email;
                        id: Uuid;
                        idRestaurant: Uuid;
                        idSender: Uuid;
                        permissions: Integer;
                        type: "invitation";
                    } | {
                        email: Email | null;
                        emailVerified: boolean;
                        id: Uuid;
                        isManaged: boolean;
                        isPhoneVerified: boolean;
                        name: NonEmptyString;
                        phone: Phone | null;
                        surname: string;
                        username: NonEmptyString;
                        superadmin: (
                            0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                        );
                        permissions?: {
                            API_KEYS: boolean;
                            CUSTOMERS: boolean;
                            DASHBOARD: boolean;
                            FEEDBACK: boolean;
                            LOYALTY: boolean;
                            MENU: boolean;
                            PAYMENTS: boolean;
                            PAYOUT: boolean;
                            PILSNER_CAMPAIGN: boolean;
                            PROFILE: boolean;
                            QR: boolean;
                            RESERVATION: boolean;
                            SEE_ORIGINAL_TIP: boolean;
                            SETTINGS: boolean;
                            TABLES: boolean;
                            UNLIMITED_TIMESPAN: boolean;
                            USERS: boolean;
                        } | undefined;
                        pipeDriveUserId: Integer | null;
                        type: "user";
                        language: Language | null;
                    }
                )>;
            };
        };
        'DELETE:/users/:id': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    id: string;
                };
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/users/invitation': {
            input: {
                data: {
                    email: Email;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 201;
                result: {
                    email: Email;
                    id: Uuid;
                    idRestaurant: Uuid;
                    idSender: Uuid;
                    permissions: Integer;
                    type: "invitation";
                };
            };
        };
        'PUT:/users/invitation/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'DELETE:/users/invitation/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/users/invitation/:id/:secret': {
            input: {
                params: {
                    id: string;
                    secret: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    email: Email;
                    id: Uuid;
                    idRestaurant: Uuid;
                    idSender: Uuid;
                    permissions: Integer;
                    user: boolean;
                };
            };
        };
        'POST:/users/invitation/:id/:secret': {
            input: {
                data: {
                    email?: NonEmptyString | undefined;
                    licenceAgree?: boolean | undefined;
                    name?: NonEmptyString255 | undefined;
                    password2?: NonEmptyString | undefined;
                    password: NonEmptyString;
                    surname?: NonEmptyString | undefined;
                    language?: Language | undefined;
                    phone?: Phone | undefined;
                };
                params: {
                    id: string;
                    secret: string;
                };
            };
            result: (
                {
                    httpStatus: 201;
                    result: {
                        email: Email | null;
                        emailVerified: boolean;
                        id: Uuid;
                        isManaged: boolean;
                        isPhoneVerified: boolean;
                        name: NonEmptyString255;
                        phone: Phone | null;
                        surname: string;
                        username: NonEmptyString255;
                        superadmin: (
                            0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                        );
                        permissions?: {
                            API_KEYS: boolean;
                            CUSTOMERS: boolean;
                            DASHBOARD: boolean;
                            FEEDBACK: boolean;
                            LOYALTY: boolean;
                            MENU: boolean;
                            PAYMENTS: boolean;
                            PAYOUT: boolean;
                            PILSNER_CAMPAIGN: boolean;
                            PROFILE: boolean;
                            QR: boolean;
                            RESERVATION: boolean;
                            SEE_ORIGINAL_TIP: boolean;
                            SETTINGS: boolean;
                            TABLES: boolean;
                            UNLIMITED_TIMESPAN: boolean;
                            USERS: boolean;
                        } | undefined;
                        pipeDriveUserId: Integer | null;
                        language: Language | null;
                    };
                } | {
                    httpStatus: 401;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 403;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/users/staff': {
            input: {
                data: {
                    username: NonEmptyString255;
                    password: NonEmptyString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 201;
                result: {
                    email: Email | null;
                    emailVerified: boolean;
                    id: Uuid;
                    isManaged: boolean;
                    isPhoneVerified: boolean;
                    name: NonEmptyString;
                    phone: Phone | null;
                    surname: string;
                    username: NonEmptyString;
                    superadmin: (
                        0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                    );
                    permissions?: {
                        API_KEYS: boolean;
                        CUSTOMERS: boolean;
                        DASHBOARD: boolean;
                        FEEDBACK: boolean;
                        LOYALTY: boolean;
                        MENU: boolean;
                        PAYMENTS: boolean;
                        PAYOUT: boolean;
                        PILSNER_CAMPAIGN: boolean;
                        PROFILE: boolean;
                        QR: boolean;
                        RESERVATION: boolean;
                        SEE_ORIGINAL_TIP: boolean;
                        SETTINGS: boolean;
                        TABLES: boolean;
                        UNLIMITED_TIMESPAN: boolean;
                        USERS: boolean;
                    } | undefined;
                    pipeDriveUserId: Integer | null;
                    type: "user";
                    language: Language | null;
                };
            };
        };
        'GET:/restaurant/pos-drivers': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    posDriver: {
                        allowFeedback: boolean;
                        allowPartialPayment: boolean;
                        bookableDriver: "RESTAURANT_ADMIN" | null;
                        hideZeroItemsByDefault: boolean;
                        isTaxReceipt: boolean;
                        payableDriver: "QERKO" | "DOTYKACKA" | "OMNIVORE" | "AMADEUS" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                        pointOfSaleId: Uuid | null;
                        tableServiceDriver: "QERKO" | "AMADEUS" | "DOTYKACKA" | "SLACK" | "OMNIVORE" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                    };
                    deliveryTypeDrivers: Array<{
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    }>;
                };
            };
        };
        'GET:/current-restaurant/currencies': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    restaurant: {
                        currencies: Array<Currency>;
                    };
                };
            };
        };
        'GET:/current-restaurant/config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    restaurantConfig: {
                        showPosDriverSettings: boolean;
                        shift: {
                            changeTime: TimeString;
                            nfcSerialNumber: NfcSerialNumber;
                        } | null;
                    };
                };
            };
        };
        'GET:/current-restaurant': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    permissions: {
                        API_KEYS: boolean;
                        CUSTOMERS: boolean;
                        DASHBOARD: boolean;
                        FEEDBACK: boolean;
                        LOYALTY: boolean;
                        MENU: boolean;
                        PAYMENTS: boolean;
                        PAYOUT: boolean;
                        PILSNER_CAMPAIGN: boolean;
                        PROFILE: boolean;
                        QR: boolean;
                        RESERVATION: boolean;
                        SEE_ORIGINAL_TIP: boolean;
                        SETTINGS: boolean;
                        TABLES: boolean;
                        UNLIMITED_TIMESPAN: boolean;
                        USERS: boolean;
                    };
                    restaurant: {
                        id: Uuid;
                        isActive: boolean;
                        defaultCurrency: Currency;
                        countryId: Country;
                        name: NonEmptyString255;
                        variableSymbol: NonNegativeDecimalString;
                        identificationNumber: NonEmptyString32;
                        tipReductionForPersonel: number;
                        showWaiterNameInPayments: boolean;
                        isAdyenOnboardingAllowed: boolean;
                        isPayuOnboardingAllowed: boolean;
                        isQerkoOnboardingAllowed: boolean;
                    };
                };
            };
        };
        'GET:/restaurant': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: Array<{
                    id: Uuid;
                    name: NonEmptyString255;
                    isActive: boolean;
                    isTesting: boolean;
                    showWaiterNameInPayments: boolean;
                    tipReductionForPersonel: number;
                    variableSymbol: NonNegativeDecimalString;
                    identificationNumber: NonEmptyString32;
                    currencies: Array<Currency>;
                    defaultCurrency: Currency;
                    isPilsnerEnabled: boolean;
                    showPosDriverSettings: boolean;
                    shiftConfig: {
                        changeTime: TimeString;
                        nfcSerialNumber: NfcSerialNumber;
                    } | null;
                    countryId: Country;
                    isAdyenOnboardingAllowed: boolean;
                    isPayuOnboardingAllowed: boolean;
                    isQerkoOnboardingAllowed: boolean;
                    permissions: {
                        API_KEYS: boolean;
                        CUSTOMERS: boolean;
                        DASHBOARD: boolean;
                        FEEDBACK: boolean;
                        LOYALTY: boolean;
                        MENU: boolean;
                        PAYMENTS: boolean;
                        PAYOUT: boolean;
                        PILSNER_CAMPAIGN: boolean;
                        PROFILE: boolean;
                        QR: boolean;
                        RESERVATION: boolean;
                        SEE_ORIGINAL_TIP: boolean;
                        SETTINGS: boolean;
                        TABLES: boolean;
                        UNLIMITED_TIMESPAN: boolean;
                        USERS: boolean;
                    };
                }>;
            };
        };
        'GET:/restaurant/:id/autocomplete': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: Array<string>;
            };
        };
        'POST:/restaurant': {
            input: {
                data: (
                    {
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        businessType: BusinessType;
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        defaultCurrency: Currency;
                        deliveryHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        identificationNumber: NonEmptyString32;
                        isTesting: boolean;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        name: NonEmptyString255;
                        openingHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        showInMap: boolean;
                        taxIdentificationNumber: VATNumber | null;
                        url: HttpUrl | null;
                        countryId: (
                            "CZ" | "DE" | "AT"
                        );
                    } | {
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        businessType: BusinessType;
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        defaultCurrency: Currency;
                        deliveryHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        identificationNumber: NonEmptyString32;
                        isTesting: boolean;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        name: NonEmptyString255;
                        openingHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        showInMap: boolean;
                        taxIdentificationNumber: VATNumber | null;
                        url: HttpUrl | null;
                        countryId: "SK";
                        okp?: NonEmptyString | null | undefined;
                    } | {
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        businessType: BusinessType;
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        defaultCurrency: Currency;
                        deliveryHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        identificationNumber: NonEmptyString32;
                        isTesting: boolean;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        name: NonEmptyString255;
                        openingHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        showInMap: boolean;
                        taxIdentificationNumber: VATNumber | null;
                        url: HttpUrl | null;
                        countryId: "HU";
                        taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                    }
                );
            };
            result: (
                {
                    httpStatus: 201;
                    result: {
                        id: Uuid;
                        name: NonEmptyString255;
                        isActive: boolean;
                        isTesting: boolean;
                        showWaiterNameInPayments: boolean;
                        tipReductionForPersonel: number;
                        variableSymbol: NonNegativeDecimalString;
                        identificationNumber: NonEmptyString32;
                        currencies: Array<Currency>;
                        defaultCurrency: Currency;
                        isPilsnerEnabled: boolean;
                        showPosDriverSettings: boolean;
                        shiftConfig: {
                            changeTime: TimeString;
                            nfcSerialNumber: NfcSerialNumber;
                        } | null;
                        countryId: Country;
                        isAdyenOnboardingAllowed: boolean;
                        isPayuOnboardingAllowed: boolean;
                        isQerkoOnboardingAllowed: boolean;
                        accountManagerId: string | null;
                        addressCity: NonEmptyString;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString;
                        addressStreet: NonEmptyString;
                        addressZipCode: ZipCode;
                        allowedDataSharing: boolean;
                        businessType: string;
                        countryRegionId: NonEmptyString;
                        customHelpDesk?: {
                            name: NonEmptyString;
                            email: Email;
                        } | null | undefined;
                        defaultTip: number;
                        deliveryHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        driver?: {
                            name: string;
                            email: string;
                            activated?: boolean | undefined;
                        } | undefined;
                        hasLogo?: boolean | undefined;
                        installationBoxPrice: number;
                        isEnabledCustomHelpDesk?: boolean | undefined;
                        latitude: number;
                        longitude: number;
                        minPaymentsExcludedFromSaRewards: number;
                        minimumPaymentsPerMonth: number;
                        okp?: NonEmptyString | undefined | null;
                        openingHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        pilsnerBussinessNumber?: string | undefined;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile?: unknown;
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                            name: NonEmptyString;
                        }> | undefined;
                        qerkoBonusNewUser: number;
                        qerkoBonusNewUserMinAmount: number;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        restu?: any;
                        salesmanCutTillOverride: Integer | null;
                        salesmanId: Uuid | null;
                        serviceFee: string | null;
                        showInMap: boolean;
                        taxIdentificationNumber: VATNumber | null;
                        taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                        termsUrl: HttpUrl | null;
                        timezone: string;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        url: HttpUrl | null;
                        useMinimalBillingSince: Integer | null;
                        openStreetMapNodeId: NonEmptyString64 | null;
                        legalEntityId: Uuid;
                        permissions: {
                            API_KEYS: boolean;
                            CUSTOMERS: boolean;
                            DASHBOARD: boolean;
                            FEEDBACK: boolean;
                            LOYALTY: boolean;
                            MENU: boolean;
                            PAYMENTS: boolean;
                            PAYOUT: boolean;
                            PILSNER_CAMPAIGN: boolean;
                            PROFILE: boolean;
                            QR: boolean;
                            RESERVATION: boolean;
                            SEE_ORIGINAL_TIP: boolean;
                            SETTINGS: boolean;
                            TABLES: boolean;
                            UNLIMITED_TIMESPAN: boolean;
                            USERS: boolean;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 403;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'PUT:/restaurant': {
            input: {
                data?: any;
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/restaurant-public-profile': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        name?: NonEmptyString255 | undefined;
                        countryId?: Country | undefined;
                        url?: HttpUrl | null | undefined;
                        addressCity?: NonEmptyString64 | undefined;
                        addressDescriptiveNumber?: NonEmptyString16 | undefined;
                        addressStreet?: NonEmptyString64 | undefined;
                        addressZipCode?: ZipCode | undefined;
                        businessType?: BusinessType | undefined;
                        phone?: Phone | null | undefined;
                        externalReservationUrl?: HttpUrl | null | undefined;
                        externalOrderingUrl?: HttpUrl | null | undefined;
                        tags?: Record<RestaurantTagName, {
                            label: string;
                            value: boolean;
                            disabled?: boolean | undefined;
                            description?: string | undefined;
                        }> | undefined;
                    } | null;
                };
            };
        };
        'PUT:/restaurant-public-profile': {
            input: {
                data: {
                    name?: NonEmptyString255 | undefined;
                    countryId?: Country | undefined;
                    url?: HttpUrl | null | undefined;
                    addressCity?: NonEmptyString64 | undefined;
                    addressDescriptiveNumber?: NonEmptyString16 | undefined;
                    addressStreet?: NonEmptyString64 | undefined;
                    addressZipCode?: ZipCode | undefined;
                    businessType?: BusinessType | undefined;
                    phone?: Phone | null | undefined;
                    externalReservationUrl?: HttpUrl | null | undefined;
                    externalOrderingUrl?: HttpUrl | null | undefined;
                    tags?: Record<RestaurantTagName, {
                        label: string;
                        value: boolean;
                        disabled?: boolean | undefined;
                        description?: string | undefined;
                    }> | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/new-restaurant-profile': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    general: {
                        fields: {
                            aboutRestaurant: NonEmptyString512 | null;
                            idRestaurantType: Integer;
                            name: NonEmptyString255;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 1;
                    };
                    address: {
                        fields: {
                            addressCity: NonEmptyString64;
                            addressDescriptiveNumber: NonEmptyString16;
                            addressStreet: NonEmptyString64;
                            countryId: Country;
                            countryRegionId: NonEmptyString;
                            addressZipCode: ZipCode;
                            mapCoords: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            };
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 2;
                    };
                    contact: {
                        fields: {
                            phone: Phone | null;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 3;
                    };
                    openingHours: {
                        fields: {
                            openingHours: Array<{
                                day: Integer;
                                idRestaurant?: Uuid | undefined;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }> | null;
                            deliveryHours: Array<{
                                day: Integer;
                                idRestaurant?: Uuid | undefined;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }>;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 4;
                    };
                    tags: {
                        fields: {
                            hasGarden: boolean;
                            hasSportsBroadcasting: boolean;
                            hasAirConditioning: boolean;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 5;
                    };
                    multimedia: {
                        fields: {
                            restaurantLogo: {
                                id: Uuid;
                                name: NonEmptyString255;
                                url: NonEmptyString;
                            } | null;
                            restaurantImage: {
                                id: Uuid;
                                name: NonEmptyString255;
                                url: NonEmptyString;
                            } | null;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 6;
                    };
                    externalLinks: {
                        fields: {
                            url: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                            externalReservationUrl: HttpUrl | null;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 7;
                    };
                    wifis: {
                        fields: {
                            wifis: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }>;
                        };
                        metadata: {
                            title: NonEmptyString;
                        };
                        index: 8;
                    };
                };
            };
        };
        'PUT:/new-restaurant-profile': {
            input: {
                data: {
                    general: {
                        fields: {
                            aboutRestaurant?: NonEmptyString512 | null | undefined;
                            idRestaurantType?: Integer | undefined;
                            name?: NonEmptyString255 | undefined;
                        };
                    };
                    address: {
                        fields: {
                            addressCity?: NonEmptyString64 | undefined;
                            addressDescriptiveNumber?: NonEmptyString16 | undefined;
                            addressStreet?: NonEmptyString64 | undefined;
                            countryId?: Country | undefined;
                            countryRegionId?: NonEmptyString | undefined;
                            addressZipCode?: ZipCode | undefined;
                            mapCoords?: {
                                latitude: NonNegativeFloat;
                                longitude: NonNegativeFloat;
                            } | undefined;
                        };
                    };
                    contact: {
                        fields: {
                            phone?: Phone | null | undefined;
                        };
                    };
                    openingHours: {
                        fields: {
                            openingHours?: Array<{
                                day: Integer;
                                idRestaurant?: Uuid | undefined;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }> | null | undefined;
                            deliveryHours?: Array<{
                                day: Integer;
                                idRestaurant?: Uuid | undefined;
                                openFrom: TimeString;
                                openTo: TimeString;
                            }> | undefined;
                        };
                    };
                    tags: {
                        fields: {
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                        };
                    };
                    multimedia: {
                        fields: {
                            restaurantLogo?: {
                                id: Uuid;
                                name: NonEmptyString255;
                                url: NonEmptyString;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                                name: NonEmptyString255;
                                url: NonEmptyString;
                            } | null | undefined;
                        };
                    };
                    externalLinks: {
                        fields: {
                            url?: HttpUrl | null | undefined;
                            externalOrderingUrl?: HttpUrl | null | undefined;
                            externalReservationUrl?: HttpUrl | null | undefined;
                        };
                    };
                    wifis: {
                        fields: {
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                        };
                    };
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/restaurant-profile-tags': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    tags: Array<{
                        name: RestaurantTagName;
                        isActive: boolean | null;
                    }>;
                };
            };
        };
        'GET:/restaurant-profile': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    name: NonEmptyString255;
                    isActive: boolean;
                    isTesting: boolean;
                    showWaiterNameInPayments: boolean;
                    tipReductionForPersonel: number;
                    variableSymbol: NonNegativeDecimalString;
                    identificationNumber: NonEmptyString32;
                    currencies: Array<Currency>;
                    defaultCurrency: Currency;
                    isPilsnerEnabled: boolean;
                    showPosDriverSettings: boolean;
                    shiftConfig: {
                        changeTime: TimeString;
                        nfcSerialNumber: NfcSerialNumber;
                    } | null;
                    countryId: Country;
                    isAdyenOnboardingAllowed: boolean;
                    isPayuOnboardingAllowed: boolean;
                    isQerkoOnboardingAllowed: boolean;
                    accountManagerId: string | null;
                    addressCity: NonEmptyString;
                    addressCountry: string;
                    addressDescriptiveNumber: NonEmptyString;
                    addressStreet: NonEmptyString;
                    addressZipCode: ZipCode;
                    allowedDataSharing: boolean;
                    businessType: string;
                    countryRegionId: NonEmptyString;
                    customHelpDesk?: {
                        name: NonEmptyString;
                        email: Email;
                    } | null | undefined;
                    defaultTip: number;
                    deliveryHours: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                    driver?: {
                        name: string;
                        email: string;
                        activated?: boolean | undefined;
                    } | undefined;
                    hasLogo: boolean;
                    installationBoxPrice: number;
                    isEnabledCustomHelpDesk?: boolean | undefined;
                    latitude: number;
                    longitude: number;
                    minPaymentsExcludedFromSaRewards: number;
                    minimumPaymentsPerMonth: number;
                    okp?: NonEmptyString | undefined | null;
                    openingHours: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                    pilsnerBussinessNumber?: string | undefined;
                    pipeDriveDealId: Integer | null;
                    pipeDriveOrganizationId: Integer | null;
                    profile: {
                        aboutRestaurant: NonEmptyString512 | null;
                        phone: NonEmptyString | null;
                        wifis: Array<{
                            name: NonEmptyString;
                            pwd: string;
                        }>;
                        idRestaurantType: Integer;
                        icon: NonEmptyString255 | null;
                        hasGarden: boolean;
                        hasSportsBroadcasting: boolean;
                        hasAirConditioning: boolean;
                        restaurantLogo: {
                            id: Uuid;
                            name: NonEmptyString255;
                            url: NonEmptyString;
                        } | null;
                        restaurantImage: {
                            id: Uuid;
                            name: NonEmptyString255;
                            url: NonEmptyString;
                        } | null;
                        externalReservationUrl: HttpUrl | null;
                        externalOrderingUrl: HttpUrl | null;
                    };
                    provisionConfig?: Record<PaymentMethodType, {
                        isAllowed: boolean;
                        provisionMax: NonNegativeDecimalString;
                        transactionProvision: NonNegativeDecimalString;
                        transactionProvisionAbs: NonNegativeDecimalString;
                        transactionProvisionExcludeTip: boolean;
                        transactionProvisionMax: NonNegativeDecimalString;
                        tipProvision: NonNegativeDecimalString;
                        tipProvisionAbs: NonNegativeDecimalString;
                        tipProvisionMinTip: NonNegativeDecimalString;
                        tipProvisionExcludeMinTip: boolean;
                        tipProvisionMax: NonNegativeDecimalString;
                        additionalDataJson: Record<string, JsonValue> | null;
                        name: NonEmptyString;
                    }> | undefined;
                    qerkoBonusNewUser: number;
                    qerkoBonusNewUserMinAmount: number;
                    qerkoBonusNewUserUntilDate: Integer | null;
                    restu?: any;
                    salesmanCutTillOverride: Integer | null;
                    salesmanId: Uuid | null;
                    serviceFee: string | null;
                    showInMap: boolean;
                    taxIdentificationNumber: VATNumber | null;
                    taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                    termsUrl: HttpUrl | null;
                    timezone: string;
                    tipHandling: RestaurantTipHandling;
                    tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                    url: HttpUrl | null;
                    useMinimalBillingSince: Integer | null;
                    openStreetMapNodeId: NonEmptyString64 | null;
                    legalEntityId: Uuid;
                };
            };
        };
        'POST:/restaurant-profile': {
            input: {
                data: {
                    addressCity: NonEmptyString64;
                    addressCountry: string;
                    addressDescriptiveNumber: NonEmptyString16;
                    addressStreet: NonEmptyString64;
                    addressZipCode: ZipCode;
                    countryRegionId: Uuid;
                    deliveryHours?: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }> | undefined;
                    isDeliveryAvailable?: boolean | undefined;
                    isTakeawayAvailable?: boolean | undefined;
                    latitude: NonNegativeFloat;
                    longitude: NonNegativeFloat;
                    name: NonEmptyString255;
                    openingHours?: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }> | undefined;
                    profile: {
                        aboutRestaurant: NonEmptyString512 | null;
                        phone: NonEmptyString | null;
                        wifis?: Array<{
                            name: NonEmptyString;
                            pwd: string;
                        }> | undefined;
                        idRestaurantType: Integer;
                        icon?: NonEmptyString255 | null | undefined;
                        hasGarden?: boolean | undefined;
                        hasSportsBroadcasting?: boolean | undefined;
                        hasAirConditioning?: boolean | undefined;
                        restaurantLogo?: {
                            id: Uuid;
                        } | null | undefined;
                        restaurantImage?: {
                            id: Uuid;
                        } | null | undefined;
                        externalReservationUrl: HttpUrl | null;
                        externalOrderingUrl: HttpUrl | null;
                    };
                    url: HttpUrl | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/pos-drivers/storyous2': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        clientId?: NonEmptyString128 | null | undefined;
                        secret?: NonEmptyString128 | null | undefined;
                        merchantId?: NonEmptyString128 | null | undefined;
                        placeId?: NonEmptyString128 | null | undefined;
                    } | null;
                };
            };
        };
        'PUT:/pos-drivers/storyous2': {
            input: {
                data: {
                    clientId?: NonEmptyString128 | null | undefined;
                    secret?: NonEmptyString128 | null | undefined;
                    merchantId?: NonEmptyString128 | null | undefined;
                    placeId?: NonEmptyString128 | null | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/point-of-sale/list': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    items: Array<{
                        name: string;
                        isAllowed: boolean;
                        id: Uuid;
                        isImagesAvailable: boolean;
                        isLoyaltyProgramAvailable: boolean;
                        isMenuFromPosAvailable: boolean;
                        isOrderAvailable: boolean;
                        isTakeawayAvailable: boolean;
                        isToOrderAvailable: boolean;
                        isPaymentInAdvanceAvailable: boolean;
                        menuDriver: (typeof menuDrivers)[keyof typeof menuDrivers] | null;
                        toBillDriver: (typeof toBillOrderableDrivers)[keyof typeof toBillOrderableDrivers] | null;
                        toTableDriver: (typeof toTableOrderableDrivers)[keyof typeof toTableOrderableDrivers] | null;
                        byCustomerDriver: (typeof byCustomerOrderableDrivers)[keyof typeof byCustomerOrderableDrivers] | null;
                        byOperatorDriver: (typeof byOperatorOrderableDrivers)[keyof typeof byOperatorOrderableDrivers] | null;
                        tableServiceDriver: (typeof tableServiceDrivers)[keyof typeof tableServiceDrivers];
                        payableDriver: (typeof payableDrivers)[keyof typeof payableDrivers];
                        wikiUrl: NonEmptyString255 | null;
                    }>;
                };
            };
        };
        'GET:/point-of-sale': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    name: string;
                    isAllowed: boolean;
                    id: Uuid;
                    isImagesAvailable: boolean;
                    isLoyaltyProgramAvailable: boolean;
                    isMenuFromPosAvailable: boolean;
                    isOrderAvailable: boolean;
                    isTakeawayAvailable: boolean;
                    isToOrderAvailable: boolean;
                    isPaymentInAdvanceAvailable: boolean;
                    menuDriver: (typeof menuDrivers)[keyof typeof menuDrivers] | null;
                    toBillDriver: (typeof toBillOrderableDrivers)[keyof typeof toBillOrderableDrivers] | null;
                    toTableDriver: (typeof toTableOrderableDrivers)[keyof typeof toTableOrderableDrivers] | null;
                    byCustomerDriver: (typeof byCustomerOrderableDrivers)[keyof typeof byCustomerOrderableDrivers] | null;
                    byOperatorDriver: (typeof byOperatorOrderableDrivers)[keyof typeof byOperatorOrderableDrivers] | null;
                    tableServiceDriver: (typeof tableServiceDrivers)[keyof typeof tableServiceDrivers];
                    payableDriver: (typeof payableDrivers)[keyof typeof payableDrivers];
                    wikiUrl: NonEmptyString255 | null;
                } | null;
            };
        };
        'GET:/pos-drivers': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    byCustomerDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    byOperatorDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    toBillDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    toTableDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    posDriver: {
                        allowFeedback: boolean;
                        allowPartialPayment: boolean;
                        bookableDriver: "RESTAURANT_ADMIN" | null;
                        hideZeroItemsByDefault: boolean;
                        isTaxReceipt: boolean;
                        payableDriver: "QERKO" | "DOTYKACKA" | "OMNIVORE" | "AMADEUS" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                        pointOfSaleId: Uuid | null;
                        tableServiceDriver: "QERKO" | "AMADEUS" | "DOTYKACKA" | "SLACK" | "OMNIVORE" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                    };
                };
            };
        };
        'POST:/pos-drivers': {
            input: {
                data: {
                    byCustomerDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    byOperatorDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    toBillDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    toTableDriver: {
                        deliveryType: OrderDeliveryType;
                        forcePaymentInAdvance: boolean;
                        isEnabled: boolean;
                        markAsPaidDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                        maxOrderTime: NonNegativeInteger | null;
                        menuDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "QERKO_MENU" | "DOTYKACKA" | "OMNIVORE" | "ASSECO" | "SMARTPOS" | "STORYOUS" | "STORYOUS_V2" | null;
                        minOrderTime: NonNegativeInteger | null;
                        orderPriceLimit: NonNegativeFloat;
                        orderableDriver: "AMADEUS" | "QERKO" | "SEPTIM" | "SLACK" | "RESTAURANT_ADMIN" | "DOTYKACKA" | "HARSYS" | "OMNIVORE" | "SMARTPOS" | "ASSECO" | "STORYOUS" | "STORYOUS_V2" | null;
                    } | null;
                    posDriver: {
                        allowFeedback: boolean;
                        allowPartialPayment: boolean;
                        bookableDriver: "RESTAURANT_ADMIN" | null;
                        hideZeroItemsByDefault: boolean;
                        isTaxReceipt: boolean;
                        payableDriver: "QERKO" | "DOTYKACKA" | "OMNIVORE" | "AMADEUS" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                        pointOfSaleId: Uuid | null;
                        tableServiceDriver: "QERKO" | "AMADEUS" | "DOTYKACKA" | "SLACK" | "OMNIVORE" | "PAPAYAPOS" | "STORYOUS_V2" | "MOCK";
                    };
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/refresh-menu': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/asseco/options': {
            input: {
                query: {
                    systemId: NonEmptyString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        paymentTypeList: Array<{
                            id: Integer;
                            name: NonEmptyString;
                            enabled: boolean;
                        }>;
                        orderTypeList: Array<{
                            id: Integer;
                            name: NonEmptyString;
                            takeaway: boolean;
                        }>;
                        discountList: Array<{
                            id: Integer;
                            name: NonEmptyString;
                            type: NonEmptyString;
                            enabled: boolean;
                        }>;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/pos-drivers/asseco': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    systemId: NonEmptyString;
                    paymentTypeId: Integer;
                    toTableOrderTypeId: Integer;
                    byCustomerOrderTypeId: Integer | null;
                    discountId: Integer;
                } | null;
            };
        };
        'POST:/pos-drivers/asseco': {
            input: {
                data: {
                    systemId: NonEmptyString;
                    paymentTypeId: Integer;
                    toTableOrderTypeId: Integer;
                    byCustomerOrderTypeId: Integer | null;
                    discountId: Integer;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/septim': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    login: NonEmptyString | null;
                    password: NonEmptyString | null;
                    baseUrl: NonEmptyString;
                    imageUrl: NonEmptyString | null;
                    outletId: Integer;
                } | null;
            };
        };
        'POST:/pos-drivers/septim': {
            input: {
                data: {
                    login: NonEmptyString | null;
                    password: NonEmptyString | null;
                    baseUrl: HttpUrl;
                    imageUrl: HttpUrl | null;
                    outletId: Integer;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/smartpos': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    password: NonEmptyString;
                    remoteId: NonEmptyString;
                    username: NonEmptyString;
                } | null;
            };
        };
        'POST:/pos-drivers/smartpos': {
            input: {
                data: {
                    password: NonEmptyString;
                    remoteId: NonEmptyString;
                    username: NonEmptyString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/papayapos': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    apiKey: NonEmptyString255;
                    apiUrl: HttpUrl;
                } | null;
            };
        };
        'POST:/pos-drivers/papayapos': {
            input: {
                data: {
                    apiKey: NonEmptyString255;
                    apiUrl: HttpUrl;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/omnivore/options': {
            input: {
                query: {
                    apiKey: NonEmptyString128;
                    locationId: NonEmptyString128;
                    baseUrl: NonEmptyString128;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        employees: Array<{
                            id: string;
                            name?: string | undefined;
                        }>;
                        tenderTypes: Array<{
                            pos_id: string | null;
                            id: string;
                            allows_tips: boolean;
                            name: string;
                        }>;
                        revenueCenters: Array<{
                            pos_id: string | null;
                            id: string;
                            default: boolean;
                            name: string;
                        }>;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/pos-drivers/omnivore': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    apiKey: NonEmptyString128;
                    baseUrl: NonEmptyString128;
                    employeeId: NonEmptyString128;
                    locationId: NonEmptyString128;
                    revenueCenterId: NonEmptyString128;
                    tenderType: NonEmptyString128;
                } | null;
            };
        };
        'POST:/pos-drivers/omnivore': {
            input: {
                data: {
                    apiKey: NonEmptyString128;
                    baseUrl: NonEmptyString128;
                    employeeId: NonEmptyString128;
                    locationId: NonEmptyString128;
                    revenueCenterId: NonEmptyString128;
                    tenderType: NonEmptyString128;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/dotykacka/options': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        employees: Array<{
                            name: NonEmptyString;
                            id: NonEmptyString;
                        }>;
                        categories: Array<{
                            name: NonEmptyString;
                            id: NonEmptyString;
                        }>;
                        branches: Array<{
                            name: NonEmptyString;
                            id: NonEmptyString;
                        }>;
                        takeawayProducts: Array<{
                            name: NonEmptyString;
                            description: string | null;
                            price: NonEmptyString;
                            id: NonEmptyString;
                        }>;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/pos-drivers/dotykacka': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    isWarehouseEnabled: boolean;
                    warehouseId: NonEmptyString32 | null;
                    printType: PrintType;
                    isDisablePrint: boolean;
                    branchId: NonEmptyString | null;
                    denyCategories: Array<NonEmptyString>;
                    employeeId: NonEmptyString | null;
                    packageItems: Record<string, NonEmptyString>;
                } | null;
            };
        };
        'POST:/pos-drivers/dotykacka': {
            input: {
                data: {
                    isWarehouseEnabled: boolean;
                    warehouseId: NonEmptyString32 | null;
                    printType: PrintType;
                    isDisablePrint: boolean;
                    branchId: NonEmptyString | null;
                    denyCategories: Array<NonEmptyString>;
                    employeeId: NonEmptyString | null;
                    packageItems: Record<string, NonEmptyString>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/pos-drivers/dotykacka/email': {
            input: {
                data: {
                    email: Email;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/amadeus': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    accessToken: NonEmptyString | null;
                    clientId: NonEmptyString | null;
                    receiptDeliveryType: AmaduesReceiptDeliveryType | null;
                    secretKey: NonEmptyString | null;
                    serialNumberOfDevice: NonEmptyString | null;
                } | null;
            };
        };
        'POST:/pos-drivers/amadeus': {
            input: {
                data: {
                    receiptDeliveryType: AmaduesReceiptDeliveryType;
                    serialNumberOfDevice: NonEmptyString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/pos-drivers/amadeus/email': {
            input: {
                data: {
                    clientId: NonEmptyString;
                    email: Email;
                    secretKey: NonEmptyString;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/pos-drivers/storyous': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    clientId: NonEmptyString128;
                    secret: NonEmptyString128;
                    merchantId: NonEmptyString128;
                    placeId: NonEmptyString128;
                } | null;
            };
        };
        'GET:/pos-drivers/storyous/options': {
            input: {
                query: {
                    clientId: NonEmptyString128;
                    secret: NonEmptyString128;
                    merchantId: NonEmptyString128;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result: {
                        places: Array<{
                            name: NonEmptyString;
                            placeId: NonEmptyString128;
                        }>;
                    };
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/pos-drivers/storyous': {
            input: {
                data: {
                    clientId: NonEmptyString128;
                    secret: NonEmptyString128;
                    merchantId: NonEmptyString128;
                    placeId: NonEmptyString128;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/restaurant-types': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: Array<{
                    label: string;
                    id: Integer;
                }>;
            };
        };
        'POST:/restaurant/user/:email/reset-password': {
            input: {
                params: {
                    email: string;
                };
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 404;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/instagram/detail': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        profileId?: NonEmptyString255 | undefined;
                        message?: NonEmptyString64 | undefined;
                        isActive?: boolean | undefined;
                        numberOfImpressions?: NonNegativeInteger | undefined;
                    } | null;
                };
            };
        };
        'PUT:/instagram/detail': {
            input: {
                data: {
                    profileId?: NonEmptyString255 | undefined;
                    message?: NonEmptyString64 | undefined;
                    isActive?: boolean | undefined;
                    numberOfImpressions?: NonNegativeInteger | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/google-review/detail': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        profileId?: NonEmptyString255 | undefined;
                        message?: NonEmptyString128 | undefined;
                        isActive?: boolean | undefined;
                        numberOfImpressions?: NonNegativeInteger | undefined;
                    } | null;
                };
            };
        };
        'PUT:/google-review/detail': {
            input: {
                data: {
                    profileId?: NonEmptyString255 | undefined;
                    message?: NonEmptyString128 | undefined;
                    isActive?: boolean | undefined;
                    numberOfImpressions?: NonNegativeInteger | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/orders': {
            input: {
                query: {
                    from: MillisecondsString;
                    to: MillisecondsString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    orders: Array<{
                        id: Uuid;
                        supplierOrderId: NonEmptyString | null;
                        orderHandler: AllAvailableDrivers;
                        status: ConnectOrderStatus;
                        created: Milliseconds;
                        orderJson?: any;
                        items: Array<{
                            name: string;
                            price: string;
                        }>;
                    }>;
                };
            };
        };
        'POST:/orders/accept': {
            input: {
                data: {
                    orderId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/orders/reject': {
            input: {
                data: {
                    orderId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/orders/ready': {
            input: {
                data: {
                    orderId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/user': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        email: Email | null;
                        name: NonEmptyString;
                        surname: NonEmptyString;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/super/user': {
            input: {
                data: {
                    action: "delete";
                    ids: Array<Uuid>;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/payout-batch/:payoutBatchId/bank-transfer/:type': {
            input: {
                params: {
                    payoutBatchId: string;
                    type: string;
                };
            };
            result: {
                httpStatus: 200;
                result: any;
            };
        };
        'POST:/super/payout/send-reports': {
            input: {
                data: {
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/super/payout/create-reports-for-payout-batch': {
            input: {
                data: {
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/super/payout/send-payments': {
            input: {
                data: {
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/super/payout-batch/create': {
            input: {
                data: {
                    paymentGatewayIds: Array<PaymentGatewayId>;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    payoutBatch: {
                        id: Uuid;
                        confirmedAt: Date | null;
                        confirmedBy: NonEmptyString255 | null;
                    };
                };
            };
        };
        'POST:/super/payout-batch/confirm': {
            input: {
                data: {
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/payouts/errors': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    errors: Array<(
                        {
                            id: string;
                            type: "other";
                            message: string;
                        } | {
                            id: string;
                            type: "unexpectedPayment";
                            payload: {
                                paymentId: Integer;
                                name: "Benefit+" | "Adyen" | "ČSOB" | "PayU" | "Twisto";
                            };
                        } | {
                            id: string;
                            type: "failedPayout";
                            payload: {
                                payoutId: Uuid;
                                message: string;
                                driverName: PayoutDriverName;
                            };
                        }
                    )>;
                };
            };
        };
        'GET:/super/payout-batch': {
            input: {
                query: {
                    filter?: Record<string, string> | undefined;
                    offset?: NonNegativeIntegerString | undefined;
                    limit?: NonNegativeIntegerString | undefined;
                    orderBy?: NonEmptyString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    pagination: {
                        total: number;
                        offset: number;
                        limit: number;
                    };
                    data: Array<{
                        createdAt: Date;
                        createdBy: NonEmptyString255;
                        id: Uuid;
                        confirmedAt: Date | null;
                        confirmedBy: NonEmptyString255 | null;
                        paymentsSentAt: Date | null;
                        paymentsSentBy: NonEmptyString255 | null;
                        paymentsSentState: PayoutBatchPaymentsSentState | null;
                        reportsGeneratedAt: Date | null;
                        reportsGeneratedState: PayoutBatchReportsGeneratedState | null;
                        reportsGeneratedBy: NonEmptyString255 | null;
                        reportsSentAt: Date | null;
                        reportsSentBy: NonEmptyString255 | null;
                        reportsSentState: PayoutBatchReportsSentState | null;
                        signature: NonEmptyString128 | null;
                    }>;
                };
            };
        };
        'GET:/super/payout-batch/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    confirmedAt: Date | null;
                    confirmedBy: NonEmptyString255 | null;
                    reportsGeneratedState: PayoutBatchReportsGeneratedState | null;
                    reportsGeneratedAt: Date | null;
                    reportsSentAt: Date | null;
                    reportsSentBy: NonEmptyString255 | null;
                    reportsSentState: PayoutBatchReportsSentState | null;
                };
            };
        };
        'PUT:/super/payout-batch/:id/payments-sent': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/super/payout-batch/retry-payout': {
            input: {
                data: {
                    driverName: PayoutDriverName;
                    payoutId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'PUT:/super/payout-batch/:id/reports-sent': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'DELETE:/super/payout-batch/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'POST:/super/payout/send-monthly-reports': {
            input: {
                data: {
                    month: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/super/payout/create-reports-for-month': {
            input: {
                data: {
                    month: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/payout/report-monthly/check': {
            input: {
                query: {
                    month: string;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        missingRestaurantBankAccounts: Array<Uuid>;
                    };
                };
            };
        };
        'GET:/payout/report-monthly/detail': {
            input: {
                query: {
                    month: string;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        month: string;
                        reportsState: PayoutReportMonthlyStateType | null;
                        createdBy: NonEmptyString255 | null;
                        createdAt: Date;
                        reportsSentState: PayoutReportMonthlySentStateType | null;
                        reportsSentAt: Date | null;
                        reportsSentBy: NonEmptyString255 | null;
                        progress: {
                            processedRestaurants: number;
                        };
                    };
                };
            };
        };
        'GET:/payout/report-monthly/list': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        month: string;
                        reportsState: PayoutReportMonthlyStateType | null;
                        createdBy: NonEmptyString255 | null;
                        createdAt: Date;
                        reportsSentState: PayoutReportMonthlySentStateType | null;
                        reportsSentAt: Date | null;
                        reportsSentBy: NonEmptyString255 | null;
                    }>;
                    primaryKey: "month";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'PUT:/super/restaurant/:id': {
            input: {
                data: (
                    {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: (
                            "CZ" | "DE" | "AT"
                        );
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled: boolean;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings: boolean;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel: number;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction: Integer;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                        id: Uuid;
                    } | {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: "SK";
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled: boolean;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings: boolean;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel: number;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction: Integer;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                        id: Uuid;
                    } | {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: "HU";
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled: boolean;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings: boolean;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel: number;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction: Integer;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                        id: Uuid;
                        taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                    }
                );
                query: {
                    tableTagForce?: NonEmptyString | undefined;
                };
                params: {
                    id: string;
                };
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 409;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 422;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/super/restaurant': {
            input: {
                query: {
                    filter?: Record<string, string> | undefined;
                    offset?: NonNegativeIntegerString | undefined;
                    limit?: NonNegativeIntegerString | undefined;
                    orderBy?: NonEmptyString | undefined;
                    isActive?: IntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    pagination: {
                        total: number;
                        offset: number;
                        limit: number;
                    };
                    data: Array<{
                        id: Uuid;
                        isActive: boolean;
                        identificationNumber: NonEmptyString;
                        name: NonEmptyString;
                        variableSymbol: NonNegativeDecimalString;
                    }>;
                };
            };
        };
        'GET:/super/sodexo-workshop/:ico': {
            input: {
                params: {
                    ico: string;
                };
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    formattedAddress: string;
                    name: string;
                    workshopCode: string;
                }>;
            };
        };
        'GET:/super/restaurant/:id/edenred': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    idRestaurant: Uuid;
                    reference: string;
                    rate: number;
                } | null;
            };
        };
        'GET:/super/restaurant/:id/septim': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    outletId: number;
                    baseUrl: string;
                } | null;
            };
        };
        'PUT:/super/restaurant/:id/tags': {
            input: {
                data: {
                    tags: Array<(
                        Integer | [
                            Integer,
                            Integer,
                        ]
                    )>;
                    tagsAction: Integer;
                    namespace: TableTagNamespace;
                };
                query: {
                    tableTagForce?: NonEmptyString | undefined;
                };
                params: {
                    id: string;
                };
            };
            result: (
                {
                    httpStatus: 204;
                    result?: undefined;
                } | {
                    httpStatus: 409;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/super/restaurant/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    name: NonEmptyString255;
                    isActive: boolean;
                    isTesting: boolean;
                    showWaiterNameInPayments: boolean;
                    tipReductionForPersonel: number;
                    variableSymbol: NonNegativeDecimalString;
                    identificationNumber: NonEmptyString32;
                    currencies: Array<Currency>;
                    defaultCurrency: Currency;
                    isPilsnerEnabled: boolean;
                    showPosDriverSettings: boolean;
                    shiftConfig: {
                        changeTime: TimeString;
                        nfcSerialNumber: NfcSerialNumber;
                    } | null;
                    countryId: Country;
                    isAdyenOnboardingAllowed: boolean;
                    isPayuOnboardingAllowed: boolean;
                    isQerkoOnboardingAllowed: boolean;
                    accountManagerId: string | null;
                    addressCity: NonEmptyString;
                    addressCountry: string;
                    addressDescriptiveNumber: NonEmptyString;
                    addressStreet: NonEmptyString;
                    addressZipCode: ZipCode;
                    allowedDataSharing: boolean;
                    businessType: string;
                    countryRegionId: NonEmptyString;
                    customHelpDesk: {
                        name: NonEmptyString;
                        email: Email;
                    } | null;
                    defaultTip: number;
                    deliveryHours: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                    driver?: {
                        name: string;
                        email: string;
                        activated?: boolean | undefined;
                    } | undefined;
                    hasLogo: boolean;
                    installationBoxPrice: number;
                    isEnabledCustomHelpDesk: boolean;
                    latitude: number;
                    longitude: number;
                    minPaymentsExcludedFromSaRewards: number;
                    minimumPaymentsPerMonth: number;
                    okp?: NonEmptyString | undefined | null;
                    openingHours: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                    pilsnerBussinessNumber: string;
                    pipeDriveDealId: Integer | null;
                    pipeDriveOrganizationId: Integer | null;
                    profile: {
                        aboutRestaurant: NonEmptyString512 | null;
                        phone: NonEmptyString | null;
                        wifis: Array<{
                            name: NonEmptyString;
                            pwd: string;
                        }>;
                        idRestaurantType: Integer;
                        icon: NonEmptyString255 | null;
                        hasGarden: boolean;
                        hasSportsBroadcasting: boolean;
                        hasAirConditioning: boolean;
                        restaurantLogo: {
                            id: Uuid;
                            name: NonEmptyString255;
                            url: NonEmptyString;
                        } | null;
                        restaurantImage: {
                            id: Uuid;
                            name: NonEmptyString255;
                            url: NonEmptyString;
                        } | null;
                        externalReservationUrl: HttpUrl | null;
                        externalOrderingUrl: HttpUrl | null;
                    };
                    provisionConfig: Record<PaymentMethodType, {
                        isAllowed: boolean;
                        provisionMax: NonNegativeDecimalString;
                        transactionProvision: NonNegativeDecimalString;
                        transactionProvisionAbs: NonNegativeDecimalString;
                        transactionProvisionExcludeTip: boolean;
                        transactionProvisionMax: NonNegativeDecimalString;
                        tipProvision: NonNegativeDecimalString;
                        tipProvisionAbs: NonNegativeDecimalString;
                        tipProvisionMinTip: NonNegativeDecimalString;
                        tipProvisionExcludeMinTip: boolean;
                        tipProvisionMax: NonNegativeDecimalString;
                        additionalDataJson: Record<string, JsonValue> | null;
                        name: NonEmptyString;
                    }>;
                    qerkoBonusNewUser: number;
                    qerkoBonusNewUserMinAmount: number;
                    qerkoBonusNewUserUntilDate: Integer | null;
                    restu?: any;
                    salesmanCutTillOverride: Integer | null;
                    salesmanId: Uuid | null;
                    serviceFee: string | null;
                    showInMap: boolean;
                    taxIdentificationNumber: VATNumber | null;
                    taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                    termsUrl: HttpUrl | null;
                    timezone: string;
                    tipHandling: RestaurantTipHandling;
                    tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                    url: HttpUrl | null;
                    useMinimalBillingSince: Integer | null;
                    openStreetMapNodeId: NonEmptyString64 | null;
                    legalEntityId: Uuid;
                    permissions: {
                        API_KEYS: boolean;
                        CUSTOMERS: boolean;
                        DASHBOARD: boolean;
                        FEEDBACK: boolean;
                        LOYALTY: boolean;
                        MENU: boolean;
                        PAYMENTS: boolean;
                        PAYOUT: boolean;
                        PILSNER_CAMPAIGN: boolean;
                        PROFILE: boolean;
                        QR: boolean;
                        RESERVATION: boolean;
                        SEE_ORIGINAL_TIP: boolean;
                        SETTINGS: boolean;
                        TABLES: boolean;
                        UNLIMITED_TIMESPAN: boolean;
                        USERS: boolean;
                    };
                };
            };
        };
        'PUT:/super/restaurant/activate/:id': {
            input: {
                data: {
                    isActive: boolean;
                };
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'POST:/super/restaurant': {
            input: {
                data: (
                    {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: (
                            "CZ" | "DE" | "AT"
                        );
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled?: boolean | undefined;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings?: boolean | undefined;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel?: number | undefined;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags?: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )> | undefined;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction?: Integer | undefined;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                    } | {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: "SK";
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled?: boolean | undefined;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings?: boolean | undefined;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel?: number | undefined;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags?: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )> | undefined;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction?: Integer | undefined;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                    } | {
                        accountManagerId?: Uuid | null | undefined;
                        addressCity: NonEmptyString64;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString16;
                        addressStreet: NonEmptyString64;
                        addressZipCode: ZipCode;
                        allowedDataSharing?: boolean | undefined;
                        businessType: BusinessType;
                        countryId: "HU";
                        countryRegionId: Uuid;
                        currencies: Array<Currency>;
                        customHelpDesk: {
                            email: Email;
                            name: NonEmptyString128;
                        } | null;
                        defaultCurrency: Currency;
                        defaultTip: NonNegativeFloat;
                        deliveryHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        hasLogo: boolean;
                        identificationNumber: NonEmptyString32;
                        installationBoxPrice?: NonNegativeFloat | undefined;
                        isActive: boolean;
                        isEnabledCustomHelpDesk: boolean;
                        isPilsnerEnabled?: boolean | undefined;
                        isTesting?: boolean | undefined;
                        latitude: NonNegativeFloat;
                        longitude: NonNegativeFloat;
                        minPaymentsExcludedFromSaRewards?: Integer | undefined;
                        minimumPaymentsPerMonth?: Integer | undefined;
                        name: NonEmptyString255;
                        openingHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                        pilsnerBussinessNumber: string | null;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile: {
                            aboutRestaurant: NonEmptyString512 | null;
                            phone: NonEmptyString | null;
                            wifis?: Array<{
                                name: NonEmptyString;
                                pwd: string;
                            }> | undefined;
                            idRestaurantType: Integer;
                            icon?: NonEmptyString255 | null | undefined;
                            hasGarden?: boolean | undefined;
                            hasSportsBroadcasting?: boolean | undefined;
                            hasAirConditioning?: boolean | undefined;
                            restaurantLogo?: {
                                id: Uuid;
                            } | null | undefined;
                            restaurantImage?: {
                                id: Uuid;
                            } | null | undefined;
                            externalReservationUrl: HttpUrl | null;
                            externalOrderingUrl: HttpUrl | null;
                        };
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                        }> | undefined;
                        qerkoBonusNewUser?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserMinAmount?: NonNegativeFloat | undefined;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        salesmanCutTillOverride?: Integer | null | undefined;
                        salesmanId?: Uuid | null | undefined;
                        serviceFee?: string | null | undefined;
                        shiftConfig?: {
                            changeTime?: TimeWithMillisecondsString | null | undefined;
                            nfcSerialNumber: NfcSerialNumber | null;
                            restaurantId?: Uuid | null | undefined;
                        } | null | undefined;
                        showInMap?: boolean | undefined;
                        showPosDriverSettings?: boolean | undefined;
                        showWaiterNameInPayments?: boolean | undefined;
                        taxIdentificationNumber: VATNumber | null;
                        termsUrl?: HttpUrl | null | undefined;
                        timezone: Timezone;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        tipReductionForPersonel?: number | undefined;
                        url: HttpUrl | null;
                        useMinimalBillingSince?: Integer | null | undefined;
                        variableSymbol: NonNegativeDecimalString;
                        isDeliveryAvailable?: boolean | undefined;
                        isTableOrderingAvailable?: boolean | undefined;
                        isTakeawayAvailable?: boolean | undefined;
                        isToBillOrderingAvailable?: boolean | undefined;
                        okp?: NonEmptyString | null | undefined;
                        permissions?: unknown;
                        restaurantTags?: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )> | undefined;
                        restaurantTagsAction?: Integer | undefined;
                        restu?: any;
                        tableTags: Array<(
                            Integer | [
                                Integer,
                                Integer,
                            ]
                        )>;
                        tableTagsAction?: Integer | undefined;
                        openStreetMapNodeId?: NonEmptyString64 | null | undefined;
                        taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                    }
                );
                query: {
                    tableTagForce?: NonEmptyString | undefined;
                };
            };
            result: (
                {
                    httpStatus: 201;
                    result: {
                        id: Uuid;
                        name: NonEmptyString255;
                        isActive: boolean;
                        isTesting: boolean;
                        showWaiterNameInPayments: boolean;
                        tipReductionForPersonel: number;
                        variableSymbol: NonNegativeDecimalString;
                        identificationNumber: NonEmptyString32;
                        currencies: Array<Currency>;
                        defaultCurrency: Currency;
                        isPilsnerEnabled: boolean;
                        showPosDriverSettings: boolean;
                        shiftConfig: {
                            changeTime: TimeString;
                            nfcSerialNumber: NfcSerialNumber;
                        } | null;
                        countryId: Country;
                        isAdyenOnboardingAllowed: boolean;
                        isPayuOnboardingAllowed: boolean;
                        isQerkoOnboardingAllowed: boolean;
                        accountManagerId: string | null;
                        addressCity: NonEmptyString;
                        addressCountry: string;
                        addressDescriptiveNumber: NonEmptyString;
                        addressStreet: NonEmptyString;
                        addressZipCode: ZipCode;
                        allowedDataSharing: boolean;
                        businessType: string;
                        countryRegionId: NonEmptyString;
                        customHelpDesk?: {
                            name: NonEmptyString;
                            email: Email;
                        } | null | undefined;
                        defaultTip: number;
                        deliveryHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        driver?: {
                            name: string;
                            email: string;
                            activated?: boolean | undefined;
                        } | undefined;
                        hasLogo?: boolean | undefined;
                        installationBoxPrice: number;
                        isEnabledCustomHelpDesk?: boolean | undefined;
                        latitude: number;
                        longitude: number;
                        minPaymentsExcludedFromSaRewards: number;
                        minimumPaymentsPerMonth: number;
                        okp?: NonEmptyString | undefined | null;
                        openingHours: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        pilsnerBussinessNumber?: string | undefined;
                        pipeDriveDealId: Integer | null;
                        pipeDriveOrganizationId: Integer | null;
                        profile?: unknown;
                        provisionConfig?: Record<PaymentMethodType, {
                            isAllowed: boolean;
                            provisionMax: NonNegativeDecimalString;
                            transactionProvision: NonNegativeDecimalString;
                            transactionProvisionAbs: NonNegativeDecimalString;
                            transactionProvisionExcludeTip: boolean;
                            transactionProvisionMax: NonNegativeDecimalString;
                            tipProvision: NonNegativeDecimalString;
                            tipProvisionAbs: NonNegativeDecimalString;
                            tipProvisionMinTip: NonNegativeDecimalString;
                            tipProvisionExcludeMinTip: boolean;
                            tipProvisionMax: NonNegativeDecimalString;
                            additionalDataJson: Record<string, JsonValue> | null;
                            name: NonEmptyString;
                        }> | undefined;
                        qerkoBonusNewUser: number;
                        qerkoBonusNewUserMinAmount: number;
                        qerkoBonusNewUserUntilDate: Integer | null;
                        restu?: any;
                        salesmanCutTillOverride: Integer | null;
                        salesmanId: Uuid | null;
                        serviceFee: string | null;
                        showInMap: boolean;
                        taxIdentificationNumber: VATNumber | null;
                        taxPayerIdentificationNumber?: TaxIdHungary | undefined;
                        termsUrl: HttpUrl | null;
                        timezone: string;
                        tipHandling: RestaurantTipHandling;
                        tipPayoutCalculationMethod: RestaurantPayoutTipCalculationMethod;
                        url: HttpUrl | null;
                        useMinimalBillingSince: Integer | null;
                        openStreetMapNodeId: NonEmptyString64 | null;
                        legalEntityId: Uuid;
                        permissions: {
                            API_KEYS: boolean;
                            CUSTOMERS: boolean;
                            DASHBOARD: boolean;
                            FEEDBACK: boolean;
                            LOYALTY: boolean;
                            MENU: boolean;
                            PAYMENTS: boolean;
                            PAYOUT: boolean;
                            PILSNER_CAMPAIGN: boolean;
                            PROFILE: boolean;
                            QR: boolean;
                            RESERVATION: boolean;
                            SEE_ORIGINAL_TIP: boolean;
                            SETTINGS: boolean;
                            TABLES: boolean;
                            UNLIMITED_TIMESPAN: boolean;
                            USERS: boolean;
                        };
                    };
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                } | {
                    httpStatus: 409;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/super/restaurant/:id/tags': {
            input: {
                query: {
                    namespace: TableTagNamespace;
                };
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: Array<{
                    id: string;
                    namespace: TableTagNamespace;
                    mode: TableTagMode;
                    idRestaurant: Uuid;
                    cIdTable: NonEmptyString | null;
                }>;
            };
        };
        'GET:/super/qrcodes': {
            input: {
                query: {
                    filter?: Record<string, unknown> | undefined;
                    offset?: NonNegativeIntegerString | undefined;
                    limit?: NonNegativeIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    pagination: {
                        total: number;
                        offset: number;
                        limit: number;
                    };
                    data: Array<{
                        qr: string;
                        restaurant: string;
                        table: string | null;
                        type: string;
                    }>;
                };
            };
        };
        'GET:/super/payments': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Integer;
                        paymentGatewayId: PaymentGatewayId;
                        createdAt: Date;
                        restaurant: {
                            name: NonEmptyString255;
                        } | null;
                        cardNumber: NonEmptyString64 | null;
                        state: PaymentState;
                        totalAmount: NonNegativeDecimalString;
                        qerkoBonus: NonNegativeDecimalString;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/super/payments': {
            input: {
                data: {
                    action: "cancel" | "confirm";
                    ids: Array<Integer>;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/payment': {
            input: {
                query: {
                    filter?: Record<string, string> | undefined;
                    offset?: NonNegativeIntegerString | undefined;
                    limit?: NonNegativeIntegerString | undefined;
                    orderBy?: NonEmptyString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    pagination: {
                        total: number;
                        offset: number;
                        limit: number;
                    };
                    data: Array<{
                        accountManagerId: string | null;
                        additionalDataJson?: any;
                        additionalProvision: number;
                        addressRestaurant: string | null;
                        authCode: string | null;
                        billInfo?: any;
                        cIdBill: string | null;
                        cIdWaiter: NonEmptyString | null;
                        clientIp: string | null;
                        createdAt: Date;
                        currency: Currency;
                        description: string | null;
                        discountJson?: any;
                        errorReason: string | null;
                        fixProvision: number;
                        gatewayId: string;
                        gatewayPaymentId: string | null;
                        id: Integer | null;
                        idCard: Uuid | null;
                        idPaymentMethod: string;
                        idPayoutBatch: Uuid | null;
                        idPointOfSale: Uuid | null;
                        idRestaurant: string | null;
                        idUser: Uuid | null;
                        isTesting: boolean;
                        items?: any;
                        language: string;
                        lastError: string | null;
                        lastStep: string | null;
                        nameRestaurant: string | null;
                        nettoAmount: DecimalString | null;
                        numberCard: string | null;
                        orderType: unknown | null;
                        partsJson?: any;
                        posProvision: number;
                        processHttpBody: string | null;
                        processHttpHeadersJson: Array<{
                            name: string;
                            value: string;
                        }> | null;
                        processHttpMethod: string;
                        processUrl: string | null;
                        provisionConfig: string | null;
                        qerkoBonus: number;
                        receiptBccJson?: any;
                        receiptId: string | null;
                        salesmanId: string | null;
                        state: PaymentState;
                        tableName: string | null;
                        timestamp: Date;
                        tip: number;
                        tipProvision: number;
                        totalAmount: number;
                        totalItems: string;
                        transactionProvision: number;
                        variableSymbol: NonNegativeDecimalString | null;
                        posCardId: Uuid | null;
                        isHidden: boolean;
                        userFriendlyError: NonEmptyString255 | null;
                        upcharges: Array<{
                            id: NonEmptyString255;
                            name: NonEmptyString255;
                            price: NonNegativeDecimalString;
                            additionalData: JsonValue | null;
                        }>;
                    }>;
                };
            };
        };
        'GET:/super/payment/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    accountManagerId: string | null;
                    additionalDataJson?: any;
                    additionalProvision: number;
                    addressRestaurant: string | null;
                    authCode: string | null;
                    billInfo?: any;
                    cIdBill: string | null;
                    cIdWaiter: NonEmptyString | null;
                    clientIp: string | null;
                    createdAt: Date;
                    currency: Currency;
                    description: string | null;
                    discountJson?: any;
                    errorReason: string | null;
                    fixProvision: number;
                    gatewayId: string;
                    gatewayPaymentId: string | null;
                    id: Integer | null;
                    idCard: Uuid | null;
                    idPaymentMethod: string;
                    idPayoutBatch: Uuid | null;
                    idPointOfSale: Uuid | null;
                    idRestaurant: string | null;
                    idUser: Uuid | null;
                    isTesting: boolean;
                    items?: any;
                    language: string;
                    lastError: string | null;
                    lastStep: string | null;
                    nameRestaurant: string | null;
                    nettoAmount: DecimalString | null;
                    numberCard: string | null;
                    orderType: unknown | null;
                    partsJson?: any;
                    posProvision: number;
                    processHttpBody: string | null;
                    processHttpHeadersJson: Array<{
                        name: string;
                        value: string;
                    }> | null;
                    processHttpMethod: string;
                    processUrl: string | null;
                    provisionConfig: string | null;
                    qerkoBonus: number;
                    receiptBccJson?: any;
                    receiptId: string | null;
                    salesmanId: string | null;
                    state: PaymentState;
                    tableName: string | null;
                    timestamp: Date;
                    tip: number;
                    tipProvision: number;
                    totalAmount: number;
                    totalItems: string;
                    transactionProvision: number;
                    variableSymbol: NonNegativeDecimalString | null;
                    posCardId: Uuid | null;
                    isHidden: boolean;
                    userFriendlyError: NonEmptyString255 | null;
                    upcharges: Array<{
                        id: NonEmptyString255;
                        name: NonEmptyString255;
                        price: NonNegativeDecimalString;
                        additionalData: JsonValue | null;
                    }>;
                };
            };
        };
        'POST:/super/payment/:id/user-details': {
            input: {
                data: {
                    reason: NonEmptyString;
                };
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    user: {
                        id: Uuid;
                        email: Email;
                        name: NonEmptyString;
                        surname: NonEmptyString;
                        phone: Phone | null;
                    };
                };
            };
        };
        'POST:/super/cancel-payment': {
            input: {
                data: {
                    paymentId: Integer;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'POST:/super/confirm-payment': {
            input: {
                data: {
                    paymentId: Integer;
                };
            };
            result: (
                {
                    httpStatus: 200;
                    result?: undefined;
                } | {
                    httpStatus: 400;
                    result: {
                        message: string;
                        reason: string | null;
                    };
                }
            );
        };
        'GET:/super/payment-gateway/synchronize': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/payment-gateway/dashboard': {
            input: {
                query: {
                    dateFrom: NonEmptyString;
                    dateTo: NonEmptyString;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    isAllowedGenerateDailyReports: boolean;
                    gateways: Array<{
                        gatewayId: PaymentGatewayId;
                        gatewayIds: Array<PaymentGatewayId>;
                        paymentCountInQerko: number;
                        paid: number;
                        unpaid: number;
                        paired: number;
                        unpaired: number;
                        unprocessedFiles: number | null;
                    }>;
                };
            };
        };
        'GET:/super/table-tag-features': {
            input: {
                query: {
                    offset?: NonNegativeIntegerString | undefined;
                    limit?: NonNegativeIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    pagination: {
                        total: number;
                        offset: number;
                        limit: number;
                    };
                    data: Array<{
                        feature: Array<string>;
                        id: Uuid;
                        idFrom: number;
                        idTo: number;
                    }>;
                };
            };
        };
        'POST:/super/table-tag-features': {
            input: {
                data: {
                    idFrom: Integer;
                    idTo: Integer;
                    feature: Array<NonEmptyString>;
                };
            };
            result: {
                httpStatus: 201;
                result?: undefined;
            };
        };
        'DELETE:/super/table-tag-features/:id': {
            input: {
                params: {
                    id: string;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/private/statistics': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    SK: {
                        countPaymentsToday: number;
                        greatestTipToday: number;
                        totalAmountToday: number;
                        totalTipToday: number;
                    };
                    CZ: {
                        countPaymentsToday: number;
                        greatestTipToday: number;
                        totalAmountToday: number;
                        totalTipToday: number;
                    };
                    DE: {
                        countPaymentsToday: number;
                        greatestTipToday: number;
                        totalAmountToday: number;
                        totalTipToday: number;
                    };
                    AT: {
                        countPaymentsToday: number;
                        greatestTipToday: number;
                        totalAmountToday: number;
                        totalTipToday: number;
                    };
                    HU: {
                        countPaymentsToday: number;
                        greatestTipToday: number;
                        totalAmountToday: number;
                        totalTipToday: number;
                    };
                };
            };
        };
        'GET:/private/statistics/reservation': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    reservations: {
                        confirmed: NonNegativeInteger;
                        rejected: NonNegativeInteger;
                        waiting: NonNegativeInteger;
                    };
                };
            };
        };
        'GET:/super/message-stories': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        restaurantId: Uuid;
                        restaurantName: NonEmptyString255;
                        isActive: boolean;
                        createdAt: Date;
                        startAt: Date;
                        expireAt: Date;
                        title: NonEmptyString255 | null;
                        numberOfImpressions: NonNegativeInteger;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/super/message-stories': {
            input: {
                data: {
                    action: "enable" | "disable" | "delete";
                    ids: Array<Uuid>;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/super/menu-stories': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        restaurantId: Uuid;
                        restaurantName: NonEmptyString255;
                        isActive: boolean;
                        createdAt: Date;
                        startAt: Date;
                        expireAt: Date;
                        title: NonEmptyString255 | null;
                        numberOfImpressions: NonNegativeInteger;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/super/menu-stories': {
            input: {
                data: {
                    action: "enable" | "disable" | "delete";
                    ids: Array<Uuid>;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/reservation/list': {
            input: {
                query: {
                    from: DateString;
                    to: DateString;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    confirmedReservations: Array<{
                        id: Uuid;
                        createdAt: Date;
                        initializedAt: Date | null;
                        canceledAt: Date | null;
                        confirmedAt: Date | null;
                        rejectedAt: Date | null;
                        reservationTime: Date;
                        seats: Integer;
                        note: NonEmptyString255 | null;
                        userNote: NonEmptyString255 | null;
                        userPhone: Phone;
                        userEmail: Email;
                        userName: NonEmptyString;
                        source: ReservationSource | null;
                        subSource: ReservationSubSource | null;
                    }>;
                    unconfirmedReservations: Array<{
                        id: Uuid;
                        createdAt: Date;
                        initializedAt: Date | null;
                        canceledAt: Date | null;
                        confirmedAt: Date | null;
                        rejectedAt: Date | null;
                        reservationTime: Date;
                        seats: Integer;
                        note: NonEmptyString255 | null;
                        userNote: NonEmptyString255 | null;
                        userPhone: Phone;
                        userEmail: Email;
                        userName: NonEmptyString;
                        source: ReservationSource | null;
                        subSource: ReservationSubSource | null;
                    }>;
                };
            };
        };
        'POST:/reservation/reject': {
            input: {
                data: {
                    reservationId: Uuid;
                    reasonMessage: NonEmptyString255;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result?: unknown;
                } | {
                    httpStatus: 400;
                    result: {
                        message: NonEmptyString;
                    };
                }
            );
        };
        'POST:/reservation/confirm': {
            input: {
                data: {
                    reservationId: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: (
                {
                    httpStatus: 200;
                    result?: unknown;
                } | {
                    httpStatus: 400;
                    result: {
                        message: NonEmptyString;
                    };
                }
            );
        };
        'POST:/reservation/edit': {
            input: {
                data: {
                    reservationId: Uuid;
                    note?: NonEmptyString255 | null | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/reservation/configuration': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    restaurantReservationConfig: {
                        maxSeatsForReservation: NonNegativeInteger;
                        minReservationStartFromNowInMinutes: NonNegativeInteger;
                        maxReservationStartFromNowInMinutes: NonNegativeInteger;
                        numberOfTables: NonNegativeInteger | null;
                        reservationLengthInMinutes: PositiveInteger | null;
                        minReservationLengthInMinutes: PositiveInteger | null;
                        seatsPerTable: PositiveInteger | null;
                        isActive: boolean;
                        reservationHours?: Array<{
                            day: Integer;
                            idRestaurant?: Uuid | undefined;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }> | undefined;
                    };
                };
            };
        };
        'POST:/reservation/configure': {
            input: {
                data: {
                    maxSeatsForReservation?: NonNegativeInteger | undefined;
                    minReservationStartFromNowInMinutes?: NonNegativeInteger | undefined;
                    maxReservationStartFromNowInMinutes?: NonNegativeInteger | undefined;
                    reservationLengthInMinutes?: PositiveInteger | undefined;
                    minReservationLengthInMinutes?: PositiveInteger | undefined;
                    numberOfTables?: NonNegativeInteger | null | undefined;
                    seatsPerTable?: PositiveInteger | null | undefined;
                    isActive?: boolean | undefined;
                    reservationHours?: Array<{
                        day: Integer;
                        idRestaurant?: Uuid | undefined;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }> | null | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu/menu-data-for-pdf': {
            input: {
                query: {
                    restaurantId: Uuid;
                    from?: DateString | null | undefined;
                    to?: DateString | null | undefined;
                    usePermanentMenu?: NonEmptyString | undefined;
                    useDailyMenu?: NonEmptyString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    dailyMenuData: Array<{
                        data: Array<{
                            description: NonEmptyString | null;
                            id: Uuid;
                            items: Array<{
                                description: NonEmptyString | null;
                                currency: PreferredCurrency;
                                id: Uuid;
                                images: Array<string>;
                                name: string;
                                price: string;
                                toTable: boolean;
                                toBill: boolean;
                            }>;
                            name: NonEmptyString;
                            openFrom: TimeString;
                            openTo: TimeString;
                        }>;
                        date: Date;
                    }>;
                    permanentMenuData: Array<{
                        description: NonEmptyString | null;
                        id: Uuid;
                        items: Array<{
                            description: NonEmptyString | null;
                            currency: PreferredCurrency;
                            id: Uuid;
                            images: Array<string>;
                            name: string;
                            price: string;
                            toTable: boolean;
                            toBill: boolean;
                        }>;
                        name: NonEmptyString;
                        openFrom: TimeString;
                        openTo: TimeString;
                    }>;
                };
            };
        };
        'POST:/menu/export-menu-to-pdf': {
            input: {
                data: Record<string, unknown>;
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: any;
            };
        };
        'GET:/menu/mealmenu': {
            input: {
                query: {
                    restaurantId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    mealMenus: Array<{
                        categories: Array<{
                            id: NonEmptyString128;
                            name: NonEmptyString;
                            description: string;
                            items: Array<{
                                additionalData?: Record<string, unknown> | undefined;
                                allergens: Array<NonEmptyString>;
                                deliveryTypes: Array<OrderDeliveryType>;
                                description: string;
                                id: NonEmptyString128;
                                images: Array<NonEmptyString>;
                                inStock: boolean;
                                modifiers: Array<{
                                    id: NonEmptyString128;
                                    minSelected: NonNegativeInteger;
                                    maxSelected: PositiveInteger | null;
                                    maxCount: PositiveInteger | null;
                                    name: NonEmptyString;
                                    description?: NonEmptyString | undefined;
                                    options: Array<{
                                        id: NonEmptyString128;
                                        menuItemId: NonEmptyString128;
                                        defaultSelected: NonNegativeInteger;
                                        addPrice: NonNegativeDecimalString;
                                        maxCount: PositiveInteger | null;
                                    }>;
                                }>;
                                name: NonEmptyString255;
                                orderable: boolean;
                                portions: Array<{
                                    id: NonEmptyString128;
                                    name: NonEmptyString;
                                    description: string;
                                    tagIds: Array<NonEmptyString128>;
                                    addPrice: DecimalString;
                                }>;
                                price: NonNegativeDecimalString;
                                tagIds: Array<NonEmptyString128>;
                                tax?: NonNegativeDecimalString | undefined;
                                type: MenuItemType;
                            }>;
                        }>;
                        currency: Currency;
                        currencyPrecision: NonNegativeInteger;
                        currencyTipPrecision: NonNegativeInteger;
                        deliveryTypes: Array<OrderDeliveryType>;
                        id: NonEmptyString128;
                        lang: UserLanguage;
                        maxOrderTime: NonNegativeInteger;
                        minOrderTime: NonNegativeInteger;
                        name: NonEmptyString;
                    }>;
                };
            };
        };
        'GET:/restaurant-settings': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    shiftConfig: {
                        changeTime: TimeString;
                    } | null;
                    tipReductionForPersonel: number;
                };
            };
        };
        'POST:/restaurant-settings': {
            input: {
                data: {
                    tipReductionForPersonel: NonNegativeFloat;
                    shiftConfig: {
                        changeTime: TimeString;
                    } | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    shiftConfig: {
                        changeTime: TimeString;
                    } | null;
                    tipReductionForPersonel: number;
                };
            };
        };
        'POST:/restaurant-settings/slack': {
            input: {
                data: {
                    slackId: Uuid;
                    force?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result: (
                    null | {
                        error: {
                            message: string;
                            reason: string;
                            restaurantName: string;
                        };
                    }
                );
            };
        };
        'GET:/restaurant-settings/slack': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    additionalData: {
                        phone?: Phone | undefined;
                        twilioNotification: boolean;
                    } | null;
                    id: Uuid;
                };
            };
        };
        'PUT:/restaurant-settings/slack': {
            input: {
                data: {
                    id: Uuid;
                    additionalData: {
                        phone?: Phone | undefined;
                        twilioNotification: boolean;
                    };
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/restaurant-settings/restaurant-admin-driver': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    twilioNotificationPhone: Phone | null;
                };
            };
        };
        'PUT:/restaurant-settings/restaurant-admin-driver': {
            input: {
                data: {
                    twilioNotificationPhone: Phone | null;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'GET:/user-payment': {
            input: {
                query: {
                    cursor?: IntegerString | undefined;
                    size?: IntegerString | undefined;
                    userId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Integer;
                        createdAt: Date;
                        state: PaymentState;
                        currency: Currency;
                        totalAmount: NonNegativeDecimalString;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/user': {
            input: {
                data: {
                    name: NonEmptyString255;
                    surname: NonEmptyString;
                    email: Email;
                    password: NonEmptyString;
                    passwordIsSha512?: boolean | undefined;
                    phone: Phone;
                    language?: Language | undefined;
                };
            };
            result: {
                httpStatus: 201;
                result: {
                    id: Uuid;
                    name: NonEmptyString255;
                    surname: string;
                    email: Email | null;
                    phone: Phone | null;
                    username: NonEmptyString255;
                    emailVerified: boolean;
                    isPhoneVerified: boolean;
                    isManaged: boolean;
                    pipeDriveUserId: Integer | null;
                    superadmin: (
                        0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                    );
                    language: Language | null;
                };
            };
        };
        'PUT:/user': {
            input: {
                data: {
                    name?: NonEmptyString255 | undefined;
                    surname?: NonEmptyString | undefined;
                    email?: Email | undefined;
                    phone?: Phone | undefined;
                    password?: NonEmptyString | undefined;
                    currentPassword?: NonEmptyString | undefined;
                    passwordIsSha512?: boolean | undefined;
                    language?: Language | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    name: NonEmptyString255;
                    surname: string;
                    email: Email | null;
                    phone: Phone | null;
                    username: NonEmptyString255;
                    emailVerified: boolean;
                    isPhoneVerified: boolean;
                    isManaged: boolean;
                    pipeDriveUserId: Integer | null;
                    superadmin: (
                        0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                    );
                    language: Language | null;
                };
            };
        };
        'GET:/verify-reset-password': {
            input: {
                query: {
                    token: NonEmptyString;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    id: Uuid;
                    name: NonEmptyString255;
                    surname: string;
                    email: Email | null;
                    phone: Phone | null;
                    username: NonEmptyString255;
                    emailVerified: boolean;
                    isPhoneVerified: boolean;
                    isManaged: boolean;
                    pipeDriveUserId: Integer | null;
                    superadmin: (
                        0 | Record<"USERS" | "ACT_AS_RESTAURANTS" | "CREATE_RESTAURANTS" | "PAYMENTS" | "QRCODES" | "PAYOUT" | "CANCEL_PAYMENT" | "CONFIRM_PAYMENT" | "LIVE_CONFIG", boolean>
                    );
                    language: Language | null;
                };
            };
        };
        'POST:/reset-password': {
            input: {
                data: {
                    token: NonEmptyString;
                    password: NonEmptyString;
                };
            };
            result: {
                httpStatus: 204;
                result?: undefined;
            };
        };
        'POST:/onboarding/init': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    adyenLegalEntity: {
                        id: Uuid;
                        capabilities: {
                            receiveFromPlatformPayments: {
                                enabled: boolean;
                                requested: boolean;
                                allowed: boolean;
                                verificationStatus: AdyenLegalEntityStatus;
                                problems?: Array<{
                                    verificationErrors: Array<{
                                        code: string;
                                        message: string;
                                        type: string;
                                        remediatingActions?: Array<{
                                            code: string;
                                            message: string;
                                        }> | undefined;
                                        subErrors?: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                        }> | undefined;
                                    }>;
                                }> | undefined;
                            };
                            receiveFromBalanceAccount: {
                                enabled: boolean;
                                requested: boolean;
                                allowed: boolean;
                                verificationStatus: AdyenLegalEntityStatus;
                                problems?: Array<{
                                    verificationErrors: Array<{
                                        code: string;
                                        message: string;
                                        type: string;
                                        remediatingActions?: Array<{
                                            code: string;
                                            message: string;
                                        }> | undefined;
                                        subErrors?: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                        }> | undefined;
                                    }>;
                                }> | undefined;
                            };
                            sendToBalanceAccount: {
                                enabled: boolean;
                                requested: boolean;
                                allowed: boolean;
                                verificationStatus: AdyenLegalEntityStatus;
                                problems?: Array<{
                                    verificationErrors: Array<{
                                        code: string;
                                        message: string;
                                        type: string;
                                        remediatingActions?: Array<{
                                            code: string;
                                            message: string;
                                        }> | undefined;
                                        subErrors?: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                        }> | undefined;
                                    }>;
                                }> | undefined;
                            };
                            sendToTransferInstrument: {
                                enabled: boolean;
                                requested: boolean;
                                allowed: boolean;
                                verificationStatus: AdyenLegalEntityStatus;
                                problems?: Array<{
                                    verificationErrors: Array<{
                                        code: string;
                                        message: string;
                                        type: string;
                                        remediatingActions?: Array<{
                                            code: string;
                                            message: string;
                                        }> | undefined;
                                        subErrors?: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                        }> | undefined;
                                    }>;
                                }> | undefined;
                            };
                        };
                    };
                };
            };
        };
        'POST:/onboarding/detach': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/onboarding/redirect-to-onboarding': {
            input: {
                data: {
                    redirectUrl: string;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    redirectUrl: string;
                };
            };
        };
        'GET:/onboarding/status': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: (
                    {
                        adyenLegalEntity: {
                            id: Uuid;
                            capabilities: {
                                receiveFromPlatformPayments: {
                                    enabled: boolean;
                                    requested: boolean;
                                    allowed: boolean;
                                    verificationStatus: AdyenLegalEntityStatus;
                                    problems?: Array<{
                                        verificationErrors: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                            subErrors?: Array<{
                                                code: string;
                                                message: string;
                                                type: string;
                                                remediatingActions?: Array<{
                                                    code: string;
                                                    message: string;
                                                }> | undefined;
                                            }> | undefined;
                                        }>;
                                    }> | undefined;
                                };
                                receiveFromBalanceAccount: {
                                    enabled: boolean;
                                    requested: boolean;
                                    allowed: boolean;
                                    verificationStatus: AdyenLegalEntityStatus;
                                    problems?: Array<{
                                        verificationErrors: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                            subErrors?: Array<{
                                                code: string;
                                                message: string;
                                                type: string;
                                                remediatingActions?: Array<{
                                                    code: string;
                                                    message: string;
                                                }> | undefined;
                                            }> | undefined;
                                        }>;
                                    }> | undefined;
                                };
                                sendToBalanceAccount: {
                                    enabled: boolean;
                                    requested: boolean;
                                    allowed: boolean;
                                    verificationStatus: AdyenLegalEntityStatus;
                                    problems?: Array<{
                                        verificationErrors: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                            subErrors?: Array<{
                                                code: string;
                                                message: string;
                                                type: string;
                                                remediatingActions?: Array<{
                                                    code: string;
                                                    message: string;
                                                }> | undefined;
                                            }> | undefined;
                                        }>;
                                    }> | undefined;
                                };
                                sendToTransferInstrument: {
                                    enabled: boolean;
                                    requested: boolean;
                                    allowed: boolean;
                                    verificationStatus: AdyenLegalEntityStatus;
                                    problems?: Array<{
                                        verificationErrors: Array<{
                                            code: string;
                                            message: string;
                                            type: string;
                                            remediatingActions?: Array<{
                                                code: string;
                                                message: string;
                                            }> | undefined;
                                            subErrors?: Array<{
                                                code: string;
                                                message: string;
                                                type: string;
                                                remediatingActions?: Array<{
                                                    code: string;
                                                    message: string;
                                                }> | undefined;
                                            }> | undefined;
                                        }>;
                                    }> | undefined;
                                };
                            };
                        };
                        adyenBalanceAccount: {
                            id: Uuid;
                            detachedAt: Date | null;
                        };
                    } | {
                        adyenLegalEntity: null;
                        adyenBalanceAccount: null;
                    }
                );
            };
        };
        'POST:/confirmo/get-payment-status': {
            input: {
                data: {
                    paymentIntentId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: (
                    {
                        type: "authorized";
                    } | {
                        type: "canceled";
                    } | {
                        type: "notFound";
                    } | {
                        type: "waiting";
                    }
                );
            };
        };
        'GET:/partner-campaign/info': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    partnerCampaign: {
                        id: string;
                        brand: RestaurantPartnerCampaignBrand;
                        status: RestaurantPartnerCampaignStatus;
                        usedVouchers: Integer;
                        vouchersLimit: Integer;
                    } | null;
                };
            };
        };
        'GET:/partner-campaign/items': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    partnerCampaignItems: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        units: PositiveFloat;
                    }>;
                };
            };
        };
        'POST:/partner-campaign/enable': {
            input: {
                data: {
                    vouchersLimit: Integer;
                    brand: RestaurantPartnerCampaignBrand;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/disable': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/activate': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/pause': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/modify': {
            input: {
                data: {
                    vouchersLimit?: Integer | undefined;
                    brand?: RestaurantPartnerCampaignBrand | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/create-item': {
            input: {
                data: {
                    id: Uuid;
                    name: NonEmptyString255;
                    units: PositiveFloat;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'POST:/partner-campaign/delete-item': {
            input: {
                data: {
                    id: Uuid;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/live-config/get-current-config': {
            input: Record<string, never>;
            result: {
                httpStatus: 200;
                result: {
                    liveConfig?: unknown;
                    databaseValues?: unknown;
                };
            };
        };
        'POST:/live-config/preview': {
            input: {
                data: {
                    databaseValues?: unknown;
                };
            };
            result: {
                httpStatus: 200;
                result: (
                    {
                        result: "success";
                        liveConfig?: unknown;
                    } | {
                        result: "error";
                        error: string;
                    }
                );
            };
        };
        'POST:/live-config/update': {
            input: {
                data: {
                    databaseValues?: unknown;
                };
            };
            result: {
                httpStatus: 200;
                result: (
                    {
                        result: "success";
                    } | {
                        result: "error";
                        error: string;
                    }
                );
            };
        };
        'GET:/active-bank-account': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        iban?: Iban | undefined;
                        currency?: Currency | undefined;
                        bic?: SwiftBic | null | undefined;
                        variableSymbol?: NonNegativeDecimalString | undefined;
                        specificSymbol?: NonNegativeDecimalString | undefined;
                    } | null;
                };
            };
        };
        'PUT:/active-bank-account': {
            input: {
                data: {
                    iban?: Iban | undefined;
                    currency?: Currency | undefined;
                    bic?: SwiftBic | null | undefined;
                    variableSymbol?: NonNegativeDecimalString | undefined;
                    specificSymbol?: NonNegativeDecimalString | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/bank-account': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        createdAt: Date;
                        iban: Iban;
                        currency: Currency;
                        bic: SwiftBic | null;
                        status: RestaurantBankAccountStatus;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/bank-account/:bankAccountId': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    bankAccountId: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        id: Uuid;
                        createdAt: Date;
                        iban: Iban;
                        currency: Currency;
                        bic: SwiftBic | null;
                        status: RestaurantBankAccountStatus;
                    };
                };
            };
        };
        'GET:/legal-entity/:legalEntityId': {
            input: {
                params: {
                    legalEntityId: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        name?: NonEmptyString255 | undefined;
                        email?: Email | null | undefined;
                        identificationNumber?: NonEmptyString32 | undefined;
                        taxIdentificationNumber?: VATNumber | null | undefined;
                        country?: Country | undefined;
                        city?: NonEmptyString64 | undefined;
                        descriptiveNumber?: NonEmptyString16 | undefined;
                        street?: NonEmptyString64 | undefined;
                        zipCode?: ZipCode | undefined;
                        language?: Language | undefined;
                        taxPayer?: boolean | undefined;
                    } | null;
                };
            };
        };
        'PUT:/legal-entity/:legalEntityId': {
            input: {
                data: {
                    name?: NonEmptyString255 | undefined;
                    email?: Email | null | undefined;
                    identificationNumber?: NonEmptyString32 | undefined;
                    taxIdentificationNumber?: VATNumber | null | undefined;
                    country?: Country | undefined;
                    city?: NonEmptyString64 | undefined;
                    descriptiveNumber?: NonEmptyString16 | undefined;
                    street?: NonEmptyString64 | undefined;
                    zipCode?: ZipCode | undefined;
                    language?: Language | undefined;
                    taxPayer?: boolean | undefined;
                };
                params: {
                    legalEntityId: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/restaurant-payout-report-config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        showTip?: boolean | undefined;
                        isDailyReportEmailNotificationAllowed?: boolean | undefined;
                        isMonthlyReportEmailNotificationAllowed?: boolean | undefined;
                        reportEmail?: Email | null | undefined;
                        minDaysBetweenPayouts?: NonNegativeInteger | undefined;
                        isCrossBorderPayoutAllowed?: boolean | undefined;
                    } | null;
                };
            };
        };
        'PUT:/restaurant-payout-report-config': {
            input: {
                data: {
                    showTip?: boolean | undefined;
                    isDailyReportEmailNotificationAllowed?: boolean | undefined;
                    isMonthlyReportEmailNotificationAllowed?: boolean | undefined;
                    reportEmail?: Email | null | undefined;
                    minDaysBetweenPayouts?: NonNegativeInteger | undefined;
                    isCrossBorderPayoutAllowed?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/payout-report/:term': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
                params: {
                    term: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        term: PayoutReportTerm;
                        startAt: Date;
                        endAt: Date;
                        createdAt: Date;
                        restaurantId: Uuid;
                        payoutBatchId: Uuid | null;
                        currency: Currency | null;
                        month: string | null;
                        payoutReportFiles: Array<{
                            id: Uuid;
                            name: NonEmptyString255;
                            mimetype: Mimetype;
                        }>;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/payout-report/:payoutReportFileId/download/': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    payoutReportFileId: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    httpUrl: HttpUrl;
                };
            };
        };
        'GET:/subscription': {
            input: {
                query: {
                    cursor?: string | undefined;
                    filter?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        state: OrderSubscriptionState;
                        type: OrderSubscriptionType;
                        currency: Currency;
                        total: NonNegativeDecimalString;
                        userId: Uuid | null;
                        firstPaymentId: Integer | null;
                        firstPaymentAt: Date | null;
                        nextPaymentAt: Date | null;
                        expireAt: Date | null;
                        canceledAt: Date | null;
                        user: {
                            name: NonEmptyString255;
                            surname: NonEmptyString255;
                            email: Email | null;
                            thirdPartyEmail: Email | null;
                        };
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/subscription': {
            input: {
                data: {
                    action: "cancel";
                    ids: Array<Uuid>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/subscription-payment': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    orderSubscriptionId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Integer;
                        createdAt: Date;
                        state: PaymentState;
                        currency: Currency;
                        totalAmount: NonNegativeDecimalString;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/subscription-payment': {
            input: {
                data: {
                    action: "cancel";
                    ids: Array<Integer>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/subscription-charge-batch': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        createdAt: Date;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/subscription-charge-batch-payments': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    orderSubscriptionChargeBatchId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        eshopOrderId: Uuid;
                        payment: {
                            id: Integer | null;
                            state: PaymentState;
                            currency: Currency;
                            totalAmount: NonNegativeDecimalString;
                        } | null;
                    }>;
                    primaryKey: "eshopOrderId";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/restaurant-user-paymentFee': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        enabled?: boolean | undefined;
                        amount?: NonNegativeDecimalString | undefined;
                        currency?: Currency | undefined;
                    } | null;
                };
            };
        };
        'PUT:/restaurant-user-paymentFee': {
            input: {
                data: {
                    enabled?: boolean | undefined;
                    amount?: NonNegativeDecimalString | undefined;
                    currency?: Currency | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/restaurant-business-model': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        businessModel?: RestaurantBusinessModel | undefined;
                    } | null;
                };
            };
        };
        'PUT:/restaurant-business-model': {
            input: {
                data: {
                    businessModel?: RestaurantBusinessModel | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/restaurant-onboarding-config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        isPayuOnboardingAllowed?: boolean | undefined;
                        isAdyenOnboardingAllowed?: boolean | undefined;
                        isQerkoOnboardingAllowed?: boolean | undefined;
                    } | null;
                };
            };
        };
        'PUT:/restaurant-onboarding-config': {
            input: {
                data: {
                    isPayuOnboardingAllowed?: boolean | undefined;
                    isAdyenOnboardingAllowed?: boolean | undefined;
                    isQerkoOnboardingAllowed?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/payout-batch': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        confirmedAt: Date | null;
                        createdAt: Date;
                        createdBy: NonEmptyString255;
                        reportsGeneratedAt: Date | null;
                        reportsSentAt: Date | null;
                        paymentsSentAt: Date | null;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/payout-batch/payment': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Integer;
                        gatewayId: PaymentGatewayId;
                        createdAt: Date;
                        state: PaymentState;
                        currency: Currency;
                        totalAmount: NonNegativeDecimalString;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'GET:/payout-batch/payment-statistics': {
            input: {
                query: {
                    payoutBatchId: Uuid;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        date: DateString;
                        payments: Array<{
                            gatewayId: PaymentGatewayId;
                            numberOfPayments: NonNegativeInteger;
                        }>;
                    }>;
                };
            };
        };
        'GET:/stories/message-or-image-edit/:storyId': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    storyId: string;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        mode?: "message" | "image" | undefined;
                        startAt?: Date | undefined;
                        expireAt?: Date | undefined;
                        isActive?: boolean | undefined;
                        title?: NonEmptyString255 | null | undefined;
                        content?: NonEmptyString | null | undefined;
                        image?: {
                            fileId: Uuid;
                            publicUrl: string;
                        } | null | undefined;
                        secondaryImage?: {
                            fileId: Uuid;
                            publicUrl: string;
                        } | null | undefined;
                        enableReservationButton?: boolean | undefined;
                        enableQerkoEshopButton?: boolean | undefined;
                        customButtonUrl?: HttpUrl | null | undefined;
                    } | null;
                };
            };
        };
        'PUT:/stories/message-or-image-edit/:storyId': {
            input: {
                data: {
                    mode?: "message" | "image" | undefined;
                    startAt?: Date | undefined;
                    expireAt?: Date | undefined;
                    isActive?: boolean | undefined;
                    title?: NonEmptyString255 | null | undefined;
                    content?: NonEmptyString | null | undefined;
                    image?: {
                        fileId: Uuid;
                        publicUrl: string;
                    } | null | undefined;
                    secondaryImage?: {
                        fileId: Uuid;
                        publicUrl: string;
                    } | null | undefined;
                    enableReservationButton?: boolean | undefined;
                    enableQerkoEshopButton?: boolean | undefined;
                    customButtonUrl?: HttpUrl | null | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
                params: {
                    storyId: string;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/message-stories': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        isActive: boolean;
                        createdAt: Date;
                        startAt: Date;
                        expireAt: Date;
                        title: NonEmptyString255 | null;
                        numberOfImpressions: NonNegativeInteger;
                        isReservationButtonEnabled: boolean;
                        isQerkoEshopButtonEnabled: boolean;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/message-stories': {
            input: {
                data: {
                    action: "enable" | "disable" | "delete";
                    ids: Array<Uuid>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/menu-stories': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        isActive: boolean;
                        createdAt: Date;
                        startAt: Date;
                        expireAt: Date;
                        title: NonEmptyString255 | null;
                        numberOfImpressions: NonNegativeInteger;
                        isReservationButtonEnabled: boolean;
                        isQerkoEshopButtonEnabled: boolean;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/menu-stories': {
            input: {
                data: {
                    action: "enable" | "disable" | "delete";
                    ids: Array<Uuid>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/stories/config-details': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    hasReservationUrl: boolean;
                    hasOrderingUrl: boolean;
                };
            };
        };
        'GET:/stories/followers-statistics': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    numberOfFollowers: NonNegativeInteger;
                    messageStoryExists: boolean;
                };
            };
        };
        'GET:/menu-config': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        isDailyMenuSyncEnabled?: boolean | undefined;
                        isPermanentMenuItemSyncEnabled?: boolean | undefined;
                        isPermanentCategoriesImportEnabled?: boolean | undefined;
                        isPermanentItemsImportEnabled?: boolean | undefined;
                        dailyMenuCategories?: Array<{
                            name: NonEmptyString255;
                            id: NonEmptyString128;
                        }> | undefined;
                        menuOverrideText?: Record<Language, {
                            label: string;
                            value: NonEmptyString255 | null;
                            description?: string | undefined;
                        }> | undefined;
                    } | null;
                };
            };
        };
        'PUT:/menu-config': {
            input: {
                data: {
                    isDailyMenuSyncEnabled?: boolean | undefined;
                    isPermanentMenuItemSyncEnabled?: boolean | undefined;
                    isPermanentCategoriesImportEnabled?: boolean | undefined;
                    isPermanentItemsImportEnabled?: boolean | undefined;
                    dailyMenuCategories?: Array<{
                        name: NonEmptyString255;
                        id: NonEmptyString128;
                    }> | undefined;
                    menuOverrideText?: Record<Language, {
                        label: string;
                        value: NonEmptyString255 | null;
                        description?: string | undefined;
                    }> | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/zero-items-settings': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        hideZeroItemsByDefault?: boolean | undefined;
                    } | null;
                };
            };
        };
        'PUT:/zero-items-settings': {
            input: {
                data: {
                    hideZeroItemsByDefault?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/zero-items': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        name: NonEmptyString255;
                        hide: boolean;
                        createdAt: Date;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/zero-items': {
            input: {
                data: {
                    action: "enable" | "disable";
                    ids: Array<Uuid>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/website/settings': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        isActive?: boolean | undefined;
                    } | null;
                };
            };
        };
        'PUT:/website/settings': {
            input: {
                data: {
                    isActive?: boolean | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/website/domain-settings': {
            input: {
                query: {
                    domainId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        isActive?: boolean | undefined;
                        domainName?: DomainWithLimitedSymbols | undefined;
                    } | null;
                };
            };
        };
        'PUT:/website/domain-settings': {
            input: {
                data: {
                    isActive?: boolean | undefined;
                    domainName?: DomainWithLimitedSymbols | undefined;
                };
                query: {
                    domainId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/website/domains': {
            input: {
                query: {
                    cursor?: string | undefined;
                    size?: PositiveIntegerString | undefined;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    data: Array<{
                        id: Uuid;
                        isActive: boolean;
                        isPrimaryDomain: boolean;
                        isLast: boolean;
                        domainName: DomainWithLimitedSymbols;
                    }>;
                    primaryKey: "id";
                    cursor: {
                        next: string | null;
                    };
                };
            };
        };
        'POST:/website/domains': {
            input: {
                data: {
                    action: "enable" | "disable" | "delete" | "markAsPrimary";
                    ids: Array<Uuid>;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/website/domain-detail': {
            input: {
                query: {
                    domainId: Uuid;
                    selectedRestaurantId?: Uuid | undefined;
                };
            };
            result: {
                httpStatus: 200;
                result: {
                    domain: {
                        isExternal: boolean;
                        dnsVerifications: Array<{
                            type: string;
                            name: string;
                            value: string;
                            currentValue?: string | undefined;
                        }>;
                    };
                };
            };
        };
        'GET:/card-on-file/settings': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        isActive?: boolean | undefined;
                        monthlyLimit?: NonNegativeDecimalString | undefined;
                    } | null;
                };
            };
        };
        'PUT:/card-on-file/settings': {
            input: {
                data: {
                    isActive?: boolean | undefined;
                    monthlyLimit?: NonNegativeDecimalString | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
        'GET:/szamlazz': {
            input: {
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result: {
                    data: {
                        status?: RestaurantSzamlazzStatus | undefined;
                        user?: NonEmptyString255 | undefined;
                        password?: NonEmptyString255 | undefined;
                    } | null;
                };
            };
        };
        'PUT:/szamlazz': {
            input: {
                data: {
                    status?: RestaurantSzamlazzStatus | undefined;
                    user?: NonEmptyString255 | undefined;
                    password?: NonEmptyString255 | undefined;
                };
                query?: {
                    selectedRestaurantId?: Uuid | undefined;
                } | undefined;
            };
            result: {
                httpStatus: 200;
                result?: undefined;
            };
        };
    };
    'SUBSCRIPTION': {
        '/testing/current-date': {
            input: {
                data?: undefined;
            };
            result: {
                currentDate: string;
            };
        };
        '/testing/current-date-with-message': {
            input: {
                data: {
                    message: string;
                };
            };
            result: {
                currentDate: string;
            };
        };
        '/confirmo/payment-status': {
            input: {
                data: {
                    paymentIntentId: Uuid;
                };
            };
            result: (
                {
                    type: "authorized";
                } | {
                    type: "canceled";
                } | {
                    type: "notFound";
                }
            );
        };
        '/payout-batch/details': {
            input: {
                data: {
                    payoutBatchId: Uuid;
                };
            };
            result: (
                {
                    type: "success";
                    payoutBatch: {
                        createdAt: Date;
                        createdBy: NonEmptyString255;
                        id: Uuid;
                        confirmedAt: Date | null;
                        confirmedBy: NonEmptyString255 | null;
                        paymentsSentAt: Date | null;
                        paymentsSentBy: NonEmptyString255 | null;
                        paymentsSentState: PayoutBatchPaymentsSentState | null;
                        reportsGeneratedAt: Date | null;
                        reportsGeneratedState: PayoutBatchReportsGeneratedState | null;
                        reportsGeneratedBy: NonEmptyString255 | null;
                        reportsSentAt: Date | null;
                        reportsSentBy: NonEmptyString255 | null;
                        reportsSentState: PayoutBatchReportsSentState | null;
                        signature: NonEmptyString128 | null;
                    };
                } | {
                    type: "notFound";
                }
            );
        };
    };
    'FORM': {
        '/loyalty/global-offers-edit/:offerId': {
            'GET': {
                input: {
                    params: {
                        offerId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            id?: Uuid | undefined;
                            type?: LoyaltyOfferType | undefined;
                            applyTo?: ApplyToType | undefined;
                            isOpenForNewUsers?: boolean | undefined;
                            isRepeatable?: boolean | undefined;
                            minTimeBetweenUpdates?: Milliseconds | undefined;
                            progressExpiration?: Milliseconds | undefined;
                            voucherExpiration?: Milliseconds | undefined;
                            progressMax?: NonNegativeFloat | undefined;
                            minAmount?: NonNegativeFloat | undefined;
                            startAt?: Date | null | undefined;
                            endAt?: Date | null | undefined;
                            isVisibleForRestaurant?: boolean | undefined;
                            voucherLimit?: NonNegativeInteger | null | undefined;
                            promoName?: NonEmptyString64 | null | undefined;
                            cardBrand?: CardBrand | null | undefined;
                            country?: Country | null | undefined;
                            businessType?: BusinessType | null | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        id?: Uuid | undefined;
                        type?: LoyaltyOfferType | undefined;
                        applyTo?: ApplyToType | undefined;
                        isOpenForNewUsers?: boolean | undefined;
                        isRepeatable?: boolean | undefined;
                        minTimeBetweenUpdates?: Milliseconds | undefined;
                        progressExpiration?: Milliseconds | undefined;
                        voucherExpiration?: Milliseconds | undefined;
                        progressMax?: NonNegativeFloat | undefined;
                        minAmount?: NonNegativeFloat | undefined;
                        startAt?: Date | null | undefined;
                        endAt?: Date | null | undefined;
                        isVisibleForRestaurant?: boolean | undefined;
                        voucherLimit?: NonNegativeInteger | null | undefined;
                        promoName?: NonEmptyString64 | null | undefined;
                        cardBrand?: CardBrand | null | undefined;
                        country?: Country | null | undefined;
                        businessType?: BusinessType | null | undefined;
                    };
                    params: {
                        offerId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/restaurant-public-profile': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            name?: NonEmptyString255 | undefined;
                            countryId?: Country | undefined;
                            url?: HttpUrl | null | undefined;
                            addressCity?: NonEmptyString64 | undefined;
                            addressDescriptiveNumber?: NonEmptyString16 | undefined;
                            addressStreet?: NonEmptyString64 | undefined;
                            addressZipCode?: ZipCode | undefined;
                            businessType?: BusinessType | undefined;
                            phone?: Phone | null | undefined;
                            externalReservationUrl?: HttpUrl | null | undefined;
                            externalOrderingUrl?: HttpUrl | null | undefined;
                            tags?: Record<RestaurantTagName, {
                                label: string;
                                value: boolean;
                                disabled?: boolean | undefined;
                                description?: string | undefined;
                            }> | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        name?: NonEmptyString255 | undefined;
                        countryId?: Country | undefined;
                        url?: HttpUrl | null | undefined;
                        addressCity?: NonEmptyString64 | undefined;
                        addressDescriptiveNumber?: NonEmptyString16 | undefined;
                        addressStreet?: NonEmptyString64 | undefined;
                        addressZipCode?: ZipCode | undefined;
                        businessType?: BusinessType | undefined;
                        phone?: Phone | null | undefined;
                        externalReservationUrl?: HttpUrl | null | undefined;
                        externalOrderingUrl?: HttpUrl | null | undefined;
                        tags?: Record<RestaurantTagName, {
                            label: string;
                            value: boolean;
                            disabled?: boolean | undefined;
                            description?: string | undefined;
                        }> | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/new-restaurant-profile': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        general: {
                            fields: {
                                aboutRestaurant: NonEmptyString512 | null;
                                idRestaurantType: Integer;
                                name: NonEmptyString255;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 1;
                        };
                        address: {
                            fields: {
                                addressCity: NonEmptyString64;
                                addressDescriptiveNumber: NonEmptyString16;
                                addressStreet: NonEmptyString64;
                                countryId: Country;
                                countryRegionId: NonEmptyString;
                                addressZipCode: ZipCode;
                                mapCoords: {
                                    latitude: NonNegativeFloat;
                                    longitude: NonNegativeFloat;
                                };
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 2;
                        };
                        contact: {
                            fields: {
                                phone: Phone | null;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 3;
                        };
                        openingHours: {
                            fields: {
                                openingHours: Array<{
                                    day: Integer;
                                    idRestaurant?: Uuid | undefined;
                                    openFrom: TimeString;
                                    openTo: TimeString;
                                }> | null;
                                deliveryHours: Array<{
                                    day: Integer;
                                    idRestaurant?: Uuid | undefined;
                                    openFrom: TimeString;
                                    openTo: TimeString;
                                }>;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 4;
                        };
                        tags: {
                            fields: {
                                hasGarden: boolean;
                                hasSportsBroadcasting: boolean;
                                hasAirConditioning: boolean;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 5;
                        };
                        multimedia: {
                            fields: {
                                restaurantLogo: {
                                    id: Uuid;
                                    name: NonEmptyString255;
                                    url: NonEmptyString;
                                } | null;
                                restaurantImage: {
                                    id: Uuid;
                                    name: NonEmptyString255;
                                    url: NonEmptyString;
                                } | null;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 6;
                        };
                        externalLinks: {
                            fields: {
                                url: HttpUrl | null;
                                externalOrderingUrl: HttpUrl | null;
                                externalReservationUrl: HttpUrl | null;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 7;
                        };
                        wifis: {
                            fields: {
                                wifis: Array<{
                                    name: NonEmptyString;
                                    pwd: string;
                                }>;
                            };
                            metadata: {
                                title: NonEmptyString;
                            };
                            index: 8;
                        };
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        general: {
                            fields: {
                                aboutRestaurant?: NonEmptyString512 | null | undefined;
                                idRestaurantType?: Integer | undefined;
                                name?: NonEmptyString255 | undefined;
                            };
                        };
                        address: {
                            fields: {
                                addressCity?: NonEmptyString64 | undefined;
                                addressDescriptiveNumber?: NonEmptyString16 | undefined;
                                addressStreet?: NonEmptyString64 | undefined;
                                countryId?: Country | undefined;
                                countryRegionId?: NonEmptyString | undefined;
                                addressZipCode?: ZipCode | undefined;
                                mapCoords?: {
                                    latitude: NonNegativeFloat;
                                    longitude: NonNegativeFloat;
                                } | undefined;
                            };
                        };
                        contact: {
                            fields: {
                                phone?: Phone | null | undefined;
                            };
                        };
                        openingHours: {
                            fields: {
                                openingHours?: Array<{
                                    day: Integer;
                                    idRestaurant?: Uuid | undefined;
                                    openFrom: TimeString;
                                    openTo: TimeString;
                                }> | null | undefined;
                                deliveryHours?: Array<{
                                    day: Integer;
                                    idRestaurant?: Uuid | undefined;
                                    openFrom: TimeString;
                                    openTo: TimeString;
                                }> | undefined;
                            };
                        };
                        tags: {
                            fields: {
                                hasGarden?: boolean | undefined;
                                hasSportsBroadcasting?: boolean | undefined;
                                hasAirConditioning?: boolean | undefined;
                            };
                        };
                        multimedia: {
                            fields: {
                                restaurantLogo?: {
                                    id: Uuid;
                                    name: NonEmptyString255;
                                    url: NonEmptyString;
                                } | null | undefined;
                                restaurantImage?: {
                                    id: Uuid;
                                    name: NonEmptyString255;
                                    url: NonEmptyString;
                                } | null | undefined;
                            };
                        };
                        externalLinks: {
                            fields: {
                                url?: HttpUrl | null | undefined;
                                externalOrderingUrl?: HttpUrl | null | undefined;
                                externalReservationUrl?: HttpUrl | null | undefined;
                            };
                        };
                        wifis: {
                            fields: {
                                wifis?: Array<{
                                    name: NonEmptyString;
                                    pwd: string;
                                }> | undefined;
                            };
                        };
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/pos-drivers/storyous2': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            clientId?: NonEmptyString128 | null | undefined;
                            secret?: NonEmptyString128 | null | undefined;
                            merchantId?: NonEmptyString128 | null | undefined;
                            placeId?: NonEmptyString128 | null | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        clientId?: NonEmptyString128 | null | undefined;
                        secret?: NonEmptyString128 | null | undefined;
                        merchantId?: NonEmptyString128 | null | undefined;
                        placeId?: NonEmptyString128 | null | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/instagram/detail': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            profileId?: NonEmptyString255 | undefined;
                            message?: NonEmptyString64 | undefined;
                            isActive?: boolean | undefined;
                            numberOfImpressions?: NonNegativeInteger | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        profileId?: NonEmptyString255 | undefined;
                        message?: NonEmptyString64 | undefined;
                        isActive?: boolean | undefined;
                        numberOfImpressions?: NonNegativeInteger | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/google-review/detail': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            profileId?: NonEmptyString255 | undefined;
                            message?: NonEmptyString128 | undefined;
                            isActive?: boolean | undefined;
                            numberOfImpressions?: NonNegativeInteger | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        profileId?: NonEmptyString255 | undefined;
                        message?: NonEmptyString128 | undefined;
                        isActive?: boolean | undefined;
                        numberOfImpressions?: NonNegativeInteger | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/active-bank-account': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            iban?: Iban | undefined;
                            currency?: Currency | undefined;
                            bic?: SwiftBic | null | undefined;
                            variableSymbol?: NonNegativeDecimalString | undefined;
                            specificSymbol?: NonNegativeDecimalString | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        iban?: Iban | undefined;
                        currency?: Currency | undefined;
                        bic?: SwiftBic | null | undefined;
                        variableSymbol?: NonNegativeDecimalString | undefined;
                        specificSymbol?: NonNegativeDecimalString | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/legal-entity/:legalEntityId': {
            'GET': {
                input: {
                    params: {
                        legalEntityId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            name?: NonEmptyString255 | undefined;
                            email?: Email | null | undefined;
                            identificationNumber?: NonEmptyString32 | undefined;
                            taxIdentificationNumber?: VATNumber | null | undefined;
                            country?: Country | undefined;
                            city?: NonEmptyString64 | undefined;
                            descriptiveNumber?: NonEmptyString16 | undefined;
                            street?: NonEmptyString64 | undefined;
                            zipCode?: ZipCode | undefined;
                            language?: Language | undefined;
                            taxPayer?: boolean | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        name?: NonEmptyString255 | undefined;
                        email?: Email | null | undefined;
                        identificationNumber?: NonEmptyString32 | undefined;
                        taxIdentificationNumber?: VATNumber | null | undefined;
                        country?: Country | undefined;
                        city?: NonEmptyString64 | undefined;
                        descriptiveNumber?: NonEmptyString16 | undefined;
                        street?: NonEmptyString64 | undefined;
                        zipCode?: ZipCode | undefined;
                        language?: Language | undefined;
                        taxPayer?: boolean | undefined;
                    };
                    params: {
                        legalEntityId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/restaurant-payout-report-config': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            showTip?: boolean | undefined;
                            isDailyReportEmailNotificationAllowed?: boolean | undefined;
                            isMonthlyReportEmailNotificationAllowed?: boolean | undefined;
                            reportEmail?: Email | null | undefined;
                            minDaysBetweenPayouts?: NonNegativeInteger | undefined;
                            isCrossBorderPayoutAllowed?: boolean | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        showTip?: boolean | undefined;
                        isDailyReportEmailNotificationAllowed?: boolean | undefined;
                        isMonthlyReportEmailNotificationAllowed?: boolean | undefined;
                        reportEmail?: Email | null | undefined;
                        minDaysBetweenPayouts?: NonNegativeInteger | undefined;
                        isCrossBorderPayoutAllowed?: boolean | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/restaurant-user-paymentFee': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            enabled?: boolean | undefined;
                            amount?: NonNegativeDecimalString | undefined;
                            currency?: Currency | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        enabled?: boolean | undefined;
                        amount?: NonNegativeDecimalString | undefined;
                        currency?: Currency | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/restaurant-business-model': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            businessModel?: RestaurantBusinessModel | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        businessModel?: RestaurantBusinessModel | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/restaurant-onboarding-config': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            isPayuOnboardingAllowed?: boolean | undefined;
                            isAdyenOnboardingAllowed?: boolean | undefined;
                            isQerkoOnboardingAllowed?: boolean | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        isPayuOnboardingAllowed?: boolean | undefined;
                        isAdyenOnboardingAllowed?: boolean | undefined;
                        isQerkoOnboardingAllowed?: boolean | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/stories/message-or-image-edit/:storyId': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                    params: {
                        storyId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            mode?: "message" | "image" | undefined;
                            startAt?: Date | undefined;
                            expireAt?: Date | undefined;
                            isActive?: boolean | undefined;
                            title?: NonEmptyString255 | null | undefined;
                            content?: NonEmptyString | null | undefined;
                            image?: {
                                fileId: Uuid;
                                publicUrl: string;
                            } | null | undefined;
                            secondaryImage?: {
                                fileId: Uuid;
                                publicUrl: string;
                            } | null | undefined;
                            enableReservationButton?: boolean | undefined;
                            enableQerkoEshopButton?: boolean | undefined;
                            customButtonUrl?: HttpUrl | null | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        mode?: "message" | "image" | undefined;
                        startAt?: Date | undefined;
                        expireAt?: Date | undefined;
                        isActive?: boolean | undefined;
                        title?: NonEmptyString255 | null | undefined;
                        content?: NonEmptyString | null | undefined;
                        image?: {
                            fileId: Uuid;
                            publicUrl: string;
                        } | null | undefined;
                        secondaryImage?: {
                            fileId: Uuid;
                            publicUrl: string;
                        } | null | undefined;
                        enableReservationButton?: boolean | undefined;
                        enableQerkoEshopButton?: boolean | undefined;
                        customButtonUrl?: HttpUrl | null | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                    params: {
                        storyId: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/menu-config': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            isDailyMenuSyncEnabled?: boolean | undefined;
                            isPermanentMenuItemSyncEnabled?: boolean | undefined;
                            isPermanentCategoriesImportEnabled?: boolean | undefined;
                            isPermanentItemsImportEnabled?: boolean | undefined;
                            dailyMenuCategories?: Array<{
                                name: NonEmptyString255;
                                id: NonEmptyString128;
                            }> | undefined;
                            menuOverrideText?: Record<Language, {
                                label: string;
                                value: NonEmptyString255 | null;
                                description?: string | undefined;
                            }> | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        isDailyMenuSyncEnabled?: boolean | undefined;
                        isPermanentMenuItemSyncEnabled?: boolean | undefined;
                        isPermanentCategoriesImportEnabled?: boolean | undefined;
                        isPermanentItemsImportEnabled?: boolean | undefined;
                        dailyMenuCategories?: Array<{
                            name: NonEmptyString255;
                            id: NonEmptyString128;
                        }> | undefined;
                        menuOverrideText?: Record<Language, {
                            label: string;
                            value: NonEmptyString255 | null;
                            description?: string | undefined;
                        }> | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/zero-items-settings': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            hideZeroItemsByDefault?: boolean | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        hideZeroItemsByDefault?: boolean | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/website/settings': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            isActive?: boolean | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        isActive?: boolean | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/website/domain-settings': {
            'GET': {
                input: {
                    query: {
                        domainId: Uuid;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            isActive?: boolean | undefined;
                            domainName?: DomainWithLimitedSymbols | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        isActive?: boolean | undefined;
                        domainName?: DomainWithLimitedSymbols | undefined;
                    };
                    query: {
                        domainId: Uuid;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/card-on-file/settings': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            isActive?: boolean | undefined;
                            monthlyLimit?: NonNegativeDecimalString | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        isActive?: boolean | undefined;
                        monthlyLimit?: NonNegativeDecimalString | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/szamlazz': {
            'GET': {
                input: {
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: {
                            status?: RestaurantSzamlazzStatus | undefined;
                            user?: NonEmptyString255 | undefined;
                            password?: NonEmptyString255 | undefined;
                        } | null;
                    };
                };
            };
            'PUT': {
                input: {
                    data: {
                        status?: RestaurantSzamlazzStatus | undefined;
                        user?: NonEmptyString255 | undefined;
                        password?: NonEmptyString255 | undefined;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
    };
    'GRID': {
        '/loyalty/global-vouchers': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        offerId: Uuid;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            userId: Uuid;
                            type: LoyaltyOfferType;
                            applyTo: ApplyToType;
                            isRepeatable: boolean;
                            isShareable: boolean;
                            acquired: DateString;
                            validTo: DateString;
                            consumedByPaymentId: Integer | null;
                            user: {
                                name: NonEmptyString255;
                                surname: NonEmptyString255;
                                email: Email | null;
                                thirdPartyEmail: Email | null;
                            };
                        }>;
                        primaryKey: "userId";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/loyalty/global-progresses': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        offerId: Uuid;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            userId: Uuid;
                            current: NonNegativeFloat;
                            max: NonNegativeFloat;
                            started: DateString;
                            validTo: DateString | null;
                            lastUpdate: Integer;
                            user: {
                                name: NonEmptyString255;
                                surname: NonEmptyString255;
                                email: Email | null;
                                thirdPartyEmail: Email | null;
                            };
                        }>;
                        primaryKey: "userId";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/loyalty/global-offers': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            type: LoyaltyOfferType;
                            applyTo: ApplyToType;
                            isOpenForNewUsers: boolean;
                            isRepeatable: boolean;
                            minTimeBetweenUpdates: Milliseconds;
                            progressExpiration: Milliseconds;
                            voucherExpiration: Milliseconds;
                            progressMax: NonNegativeFloat;
                            minAmount: NonNegativeFloat;
                            startAt: Date | null;
                            endAt: Date | null;
                            isVisibleForRestaurant: boolean;
                            voucherLimit: NonNegativeInteger | null;
                            usedProgress: NonNegativeInteger | null;
                            promoName: NonEmptyString64 | null;
                            cardBrand: CardBrand | null;
                            country: Country | null;
                            businessType: BusinessType | null;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/super/user': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            email: Email | null;
                            name: NonEmptyString;
                            surname: NonEmptyString;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "delete";
                        ids: Array<Uuid>;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/payout/report-monthly/list': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            month: string;
                            reportsState: PayoutReportMonthlyStateType | null;
                            createdBy: NonEmptyString255 | null;
                            createdAt: Date;
                            reportsSentState: PayoutReportMonthlySentStateType | null;
                            reportsSentAt: Date | null;
                            reportsSentBy: NonEmptyString255 | null;
                        }>;
                        primaryKey: "month";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/super/payments': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Integer;
                            paymentGatewayId: PaymentGatewayId;
                            createdAt: Date;
                            restaurant: {
                                name: NonEmptyString255;
                            } | null;
                            cardNumber: NonEmptyString64 | null;
                            state: PaymentState;
                            totalAmount: NonNegativeDecimalString;
                            qerkoBonus: NonNegativeDecimalString;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "cancel" | "confirm";
                        ids: Array<Integer>;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/super/message-stories': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            restaurantId: Uuid;
                            restaurantName: NonEmptyString255;
                            isActive: boolean;
                            createdAt: Date;
                            startAt: Date;
                            expireAt: Date;
                            title: NonEmptyString255 | null;
                            numberOfImpressions: NonNegativeInteger;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable" | "delete";
                        ids: Array<Uuid>;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/super/menu-stories': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            restaurantId: Uuid;
                            restaurantName: NonEmptyString255;
                            isActive: boolean;
                            createdAt: Date;
                            startAt: Date;
                            expireAt: Date;
                            title: NonEmptyString255 | null;
                            numberOfImpressions: NonNegativeInteger;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable" | "delete";
                        ids: Array<Uuid>;
                    };
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/user-payment': {
            'GET': {
                input: {
                    query: {
                        cursor?: IntegerString | undefined;
                        size?: IntegerString | undefined;
                        userId: Uuid;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Integer;
                            createdAt: Date;
                            state: PaymentState;
                            currency: Currency;
                            totalAmount: NonNegativeDecimalString;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/bank-account': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            createdAt: Date;
                            iban: Iban;
                            currency: Currency;
                            bic: SwiftBic | null;
                            status: RestaurantBankAccountStatus;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/payout-report/:term': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                    params: {
                        term: string;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            term: PayoutReportTerm;
                            startAt: Date;
                            endAt: Date;
                            createdAt: Date;
                            restaurantId: Uuid;
                            payoutBatchId: Uuid | null;
                            currency: Currency | null;
                            month: string | null;
                            payoutReportFiles: Array<{
                                id: Uuid;
                                name: NonEmptyString255;
                                mimetype: Mimetype;
                            }>;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/subscription': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        filter?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            state: OrderSubscriptionState;
                            type: OrderSubscriptionType;
                            currency: Currency;
                            total: NonNegativeDecimalString;
                            userId: Uuid | null;
                            firstPaymentId: Integer | null;
                            firstPaymentAt: Date | null;
                            nextPaymentAt: Date | null;
                            expireAt: Date | null;
                            canceledAt: Date | null;
                            user: {
                                name: NonEmptyString255;
                                surname: NonEmptyString255;
                                email: Email | null;
                                thirdPartyEmail: Email | null;
                            };
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "cancel";
                        ids: Array<Uuid>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/subscription-payment': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        orderSubscriptionId: Uuid;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Integer;
                            createdAt: Date;
                            state: PaymentState;
                            currency: Currency;
                            totalAmount: NonNegativeDecimalString;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "cancel";
                        ids: Array<Integer>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/subscription-charge-batch': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            createdAt: Date;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/subscription-charge-batch-payments': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        orderSubscriptionChargeBatchId: Uuid;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            eshopOrderId: Uuid;
                            payment: {
                                id: Integer | null;
                                state: PaymentState;
                                currency: Currency;
                                totalAmount: NonNegativeDecimalString;
                            } | null;
                        }>;
                        primaryKey: "eshopOrderId";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/payout-batch': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            confirmedAt: Date | null;
                            createdAt: Date;
                            createdBy: NonEmptyString255;
                            reportsGeneratedAt: Date | null;
                            reportsSentAt: Date | null;
                            paymentsSentAt: Date | null;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/payout-batch/payment': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        payoutBatchId: Uuid;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Integer;
                            gatewayId: PaymentGatewayId;
                            createdAt: Date;
                            state: PaymentState;
                            currency: Currency;
                            totalAmount: NonNegativeDecimalString;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
        };
        '/message-stories': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            isActive: boolean;
                            createdAt: Date;
                            startAt: Date;
                            expireAt: Date;
                            title: NonEmptyString255 | null;
                            numberOfImpressions: NonNegativeInteger;
                            isReservationButtonEnabled: boolean;
                            isQerkoEshopButtonEnabled: boolean;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable" | "delete";
                        ids: Array<Uuid>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/menu-stories': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            isActive: boolean;
                            createdAt: Date;
                            startAt: Date;
                            expireAt: Date;
                            title: NonEmptyString255 | null;
                            numberOfImpressions: NonNegativeInteger;
                            isReservationButtonEnabled: boolean;
                            isQerkoEshopButtonEnabled: boolean;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable" | "delete";
                        ids: Array<Uuid>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/zero-items': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            name: NonEmptyString255;
                            hide: boolean;
                            createdAt: Date;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable";
                        ids: Array<Uuid>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
        '/website/domains': {
            'GET': {
                input: {
                    query: {
                        cursor?: string | undefined;
                        size?: PositiveIntegerString | undefined;
                        selectedRestaurantId?: Uuid | undefined;
                    };
                };
                result: {
                    httpStatus: 200;
                    result: {
                        data: Array<{
                            id: Uuid;
                            isActive: boolean;
                            isPrimaryDomain: boolean;
                            isLast: boolean;
                            domainName: DomainWithLimitedSymbols;
                        }>;
                        primaryKey: "id";
                        cursor: {
                            next: string | null;
                        };
                    };
                };
            };
            'POST': {
                input: {
                    data: {
                        action: "enable" | "disable" | "delete" | "markAsPrimary";
                        ids: Array<Uuid>;
                    };
                    query?: {
                        selectedRestaurantId?: Uuid | undefined;
                    } | undefined;
                };
                result: {
                    httpStatus: 200;
                    result?: undefined;
                };
            };
        };
    };
}

export enum AmaduesReceiptDeliveryType {
    DigitalByEmail = 'digitalByEmail',
    EmployeeHasToManuallyPrint = 'employeeHasToManuallyPrint',
    Paper = 'paper',
}

export enum RestaurantMenuItemUnit {
    weight = 'weight',
    volume = 'volume',
}

export enum Currency {
    CZK = 'CZK',
    USD = 'USD',
    EUR = 'EUR',
    HUF = 'HUF',
}

export enum PrintType {
    Email = 'email',
    None = 'none',
    Local = 'local',
}

export enum Country {
    CZ = 'CZ',
    SK = 'SK',
    DE = 'DE',
    AT = 'AT',
    HU = 'HU',
}

export enum Language {
    cs = 'cs',
    en = 'en',
    sk = 'sk',
    de = 'de',
    hu = 'hu',
}

export enum TableTagMode {
    STATIC_TABLE = 'STATIC_TABLE',
    DIRECT_BILL = 'DIRECT_BILL',
}

export enum TableTagNamespace {
    TABLE = 'table',
    RESTAURANT = 'restaurant',
    RESTAURANT_ID = 'rid',
    ESHOP_ORDER = 'eso',
    POS = 'pos',
}

export enum OrderDeliveryType {
    ToTable = 'toTable',
    ToBill = 'toBill',
    ByCustomer = 'byCustomer',
    ByOperator = 'byOperator',
}

export enum ApplyToType {
    BILL = 'BILL',
    ITEM = 'ITEM',
}

export enum BonusOfferType {
    PERCENT = 'PERCENT',
    ABSOLUTE = 'ABSOLUTE',
}

export enum BusinessType {
    GASTRO = 'GASTRO',
    TV = 'TV',
    CHARGERS = 'CHARGERS',
    GAS_STATIONS = 'GAS_STATIONS',
}

export enum RestaurantTipHandling {
    NORMAL = 'NORMAL',
    NONE = 'NONE',
    INFO = 'INFO',
    NORMAL_WITHOUT_RECEIPT = 'NORMAL_WITHOUT_RECEIPT',
}

export enum LoyaltyOfferType {
    VISIT = 'VISIT',
    PRODUCT = 'PRODUCT',
    VALUE = 'VALUE',
    VIP = 'VIP',
}

export enum NotificationSubscriptionType {
    Email = 'email',
    Phone = 'phone',
    Popup = 'popup',
}

export enum RestaurantUserNotificationType {
    ReservationCreated = 'reservationCreated',
    ReservationConfirmed = 'reservationConfirmed',
    ReservationCanceled = 'reservationCanceled',
    Message = 'message',
    LoyaltyOfferCreated = 'loyaltyOfferCreated',
    NewQerkoFeature = 'newQerkoFeature',
    PosInvalidApiKey = 'posInvalidApiKey',
    PosError = 'posError',
    PartnerCampaignNearToLimit = 'partnerCampaignNearToLimit',
    PartnerCampaignReachedLimit = 'partnerCampaignReachedLimit',
}

export enum RestaurantUserNotificationAction {
    Reject = 'reject',
    Confirm = 'confirm',
    TakeALook = 'takeALook',
}

export enum RestaurantDailyMenuVariant {
    prod = 'prod',
    edit = 'edit',
}

export enum RestaurantPermanentMenuVariant {
    prod = 'prod',
    edit = 'edit',
}

export enum RestaurantBusinessModel {
    Blend = 'blend',
    InterchangePlusPlus = 'iPlusPlus',
}

export enum ReservationSource {
    Web = 'web',
    MobileApp = 'mobileApp',
}

export enum RestaurantPayoutTipCalculationMethod {
    Exact = 'exact',
    Floor = 'floor',
    Ceil = 'ceil',
}

export enum ConnectOrderStatus {
    Cancelled = 'Cancelled',
    Completed = 'Completed',
    Done = 'Done',
    Open = 'Open',
    OrderPreparing = 'OrderPreparing',
    OrderWaiting = 'OrderWaiting',
    ReadyForPickUp = 'ReadyForPickUp',
}

export enum Timezone {
    UTC = 'UTC',
    CET = 'CET',
    EuropePrague = 'Europe/Prague',
    EuropeBratislava = 'Europe/Bratislava',
    EuropeVienna = 'Europe/Vienna',
    EuropeBerlin = 'Europe/Berlin',
    EuropeBudapest = 'Europe/Budapest',
}

export enum MenuItemType {
    Sale = 'sale',
    Modifier = 'modifier',
}

export enum ReservationSubSource {
    Seznam = 'seznam',
    Google = 'google',
}

export enum PaymentMethodType {
    CSOB_GOOGLE_PAY = 'CSOB_GOOGLE_PAY',
    APPLE_PAY = 'APPLE_PAY',
    BENEFIT_PLUS = 'BENEFIT_PLUS',
    MALL_PAY = 'MALL_PAY',
    CARD = 'CARD',
    EDENRED = 'EDENRED',
    CSOB_UP = 'CSOB_UP',
    CSOB_LIDL = 'CSOB_LIDL',
    SODEXO = 'SODEXO',
    STRIPE_CARD = 'STRIPE_CARD',
    STRIPE_APPLE_PAY = 'STRIPE_APPLE_PAY',
    STRIPE_GOOGLE_PAY = 'STRIPE_GOOGLE_PAY',
    STRIPE_WEB = 'STRIPE_WEB',
    ADYEN_CARD = 'ADYEN_CARD',
    ADYEN_APPLE_PAY = 'ADYEN_APPLE_PAY',
    ADYEN_GOOGLE_PAY = 'ADYEN_GOOGLE_PAY',
    ADYEN_WEB = 'ADYEN_WEB',
    ADYEN_PAYMENT_LINK = 'ADYEN_PAYMENT_LINK',
    PAYU_APPLE_PAY = 'PAYU_APPLE_PAY',
    PAYU_GOOGLE_PAY = 'PAYU_GOOGLE_PAY',
    PAYU_CARD = 'PAYU_CARD',
    PAYU_WEB = 'PAYU_WEB',
    TWISTO = 'TWISTO',
    TWISTO_WEB = 'TWISTO_WEB',
    UPSK_FOOD_CARD = 'UPSK_FOOD_CARD',
    BTC = 'BTC',
    FINBRICKS = 'FINBRICKS',
}

export enum AdyenLegalEntityStatus {
    Pending = 'pending',
    Invalid = 'invalid',
    Valid = 'valid',
    Rejected = 'rejected',
}

export enum RestaurantPartnerCampaignBrand {
    Jagermeister = 'jagermeister',
}

export enum RestaurantPartnerCampaignStatus {
    Preparing = 'preparing',
    Activated = 'activated',
    Paused = 'paused',
}

export enum AllAvailableDrivers {
    AMADEUS = 'AMADEUS',
    QERKO = 'QERKO',
    SEPTIM = 'SEPTIM',
    SLACK = 'SLACK',
    RESTAURANT_ADMIN = 'RESTAURANT_ADMIN',
    DOTYKACKA = 'DOTYKACKA',
    QERKO_MENU = 'QERKO_MENU',
    HARSYS = 'HARSYS',
    OMNIVORE = 'OMNIVORE',
    SMARTPOS = 'SMARTPOS',
    ASSECO = 'ASSECO',
    PAPAYAPOS = 'PAPAYAPOS',
    STORYOUS = 'STORYOUS',
    STORYOUS_V2 = 'STORYOUS_V2',
    MOCK = 'MOCK',
}

export enum CardBrand {
    Visa = 'visa',
    Mastercard = 'mastercard',
}

export enum PaymentGatewayId {
    GPE = 'GPE',
    BENEFIT_PLUS = 'BENEFIT_PLUS',
    CSOB = 'CSOB',
    CSOB_TEST = 'CSOB_TEST',
    CSOB_APPLE_PAY = 'CSOB_APPLE_PAY',
    CSOB_GOOGLE_PAY = 'CSOB_GOOGLE_PAY',
    CSOB_EDENRED = 'CSOB_EDENRED',
    CSOB_UP = 'CSOB_UP',
    CSOB_LIDL = 'CSOB_LIDL',
    CSOB_MALL_PAY = 'CSOB_MALL_PAY',
    SODEXO = 'SODEXO',
    STRIPE = 'STRIPE',
    STRIPE_APPLE_PAY = 'STRIPE_APPLE_PAY',
    STRIPE_GOOGLE_PAY = 'STRIPE_GOOGLE_PAY',
    STRIPE_WEB = 'STRIPE_WEB',
    ADYEN = 'ADYEN',
    ADYEN_APPLE_PAY = 'ADYEN_APPLE_PAY',
    ADYEN_GOOGLE_PAY = 'ADYEN_GOOGLE_PAY',
    ADYEN_WEB = 'ADYEN_WEB',
    ADYEN_PAYMENT_LINK = 'ADYEN_PAYMENT_LINK',
    PAYU_APPLE_PAY = 'PAYU_APPLE_PAY',
    PAYU_GOOGLE_PAY = 'PAYU_GOOGLE_PAY',
    PAYU = 'PAYU',
    PAYU_WEB = 'PAYU_WEB',
    TWISTO = 'TWISTO',
    TWISTO_WEB = 'TWISTO_WEB',
    UPSK_FOOD_CARD = 'UPSK_FOOD_CARD',
    CONFIRMO_BTC = 'CONFIRMO_BTC',
    FINBRICKS = 'FINBRICKS',
    FAKE = 'FAKE',
}

export enum RestaurantBankAccountStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export enum PayoutReportTerm {
    Daily = 'daily',
    Monthly = 'monthly',
}

export enum Mimetype {
    Csv = 'text/csv',
    Pdf = 'application/pdf',
    Html = 'text/html',
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Webp = 'image/webp',
}

export enum PayoutBatchReportsGeneratedState {
    Starting = 'starting',
    Progress = 'progress',
    Finished = 'finished',
}

export enum PayoutBatchReportsSentState {
    Starting = 'starting',
    Progress = 'progress',
    Finished = 'finished',
}

export enum PayoutBatchPaymentsSentState {
    Starting = 'starting',
    Progress = 'progress',
    Finished = 'finished',
}

export enum OrderSubscriptionState {
    Pending = 'pending',
    Active = 'active',
    Expired = 'expired',
    Cancelled = 'cancelled',
}

export enum OrderSubscriptionType {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
    Manual = 'manual',
}

export enum PaymentState {
    REFUNDED = 'REFUNDED',
    STARTED = 'STARTED',
    PROCESSING = 'PROCESSING',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR',
}

export enum PayoutReportMonthlyStateType {
    Checking = 'checking',
    ReCheckIsRequired = 'reCheckIsRequired',
    Checked = 'checked',
    Starting = 'starting',
    Progress = 'progress',
    Finished = 'finished',
}

export enum PayoutReportMonthlySentStateType {
    Starting = 'starting',
    Progress = 'progress',
    Finished = 'finished',
}

export enum RestaurantSzamlazzStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export enum PayoutDriverName {
    Void = 'void',
    BankTransfer = 'bankTransfer',
    PayuMarketplace = 'payuMarketplace',
    AdyenMarketplace = 'adyenMarketplace',
    Sodexo = 'sodexo',
    Stripe = 'stripe',
}

export enum RestaurantTagName {
    garden = 'garden',
    sportsBroadcasting = 'sportsBroadcasting',
    airConditioning = 'airConditioning',
    brewersStar = 'beerStar',
    liftago = 'liftago',
}
