import React, { useState } from 'react';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { theme } from 'restaurant/common/theme';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from 'src/createEmotionCache';
import { config as fontawesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { ErrorBoundaryWithSentry } from 'common/components/ErrorBoundary';
import { createI18nRestaurant } from 'restaurant/i18n/i18n';

import type { EmotionCache } from '@emotion/cache';
import type { AppProps } from 'next/app';

// hack for https://github.com/vercel/next.js/issues/20682
import '@fortawesome/fontawesome-svg-core/styles.css';

fontawesomeConfig.autoAddCss = false;

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();

export default function App({ Component, pageProps, emotionCache }: AppProps & { emotionCache?: EmotionCache }) {
    const [googleReCaptchaKey] = React.useState(process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY ?? '');
    const router = useRouter();
    const isPageDailyMenu = router.asPath.match('/daily-menu');
    useState(createI18nRestaurant);
    const { t, i18n } = useTranslation(isPageDailyMenu ? 'restaurant.dailyMenu' : 'restaurant.reservation');

    return (
        <>
            <ErrorBoundaryWithSentry>
                <Head>
                    <title>{t('title')}</title>
                    {isPageDailyMenu && (<meta content={`${router.basePath}/og-image-daily-menu.png`} property="og:image" />)}
                    <link href={`${router.basePath}/favicon.ico`} rel="icon" />
                    <link href={`${router.basePath}/logo192.png`} rel="apple-touch-icon" />
                    <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
                    {

                        // At eslint package eslint-plugin-react version 7.31.7 is problem with keys global and jsx
                        //eslint-disable-next-line react/no-unknown-property
                        <style global jsx>{`
                            html,
                            body,
                            body > div:first-child,
                            div#__next,
                            div#__next > div {
                                height: 100%;
                                margin: 0;
                                minHeight: 100vh;
                                padding: 0;
                            }
                        `}
                        </style>
                    }
                </Head>
                <CacheProvider value={emotionCache ?? clientSideEmotionCache}>
                    <QueryClientProvider client={queryClient}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider>
                                <GoogleReCaptchaProvider
                                    language={i18n.language}
                                    reCaptchaKey={googleReCaptchaKey}
                                    scriptProps={{
                                        appendTo: 'head',
                                        async: false,
                                        defer: false,
                                        nonce: undefined,
                                    }}
                                    useEnterprise={false}
                                >
                                    <CssBaseline />
                                    <Component {...pageProps} />
                                </GoogleReCaptchaProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </QueryClientProvider>
                </CacheProvider>
            </ErrorBoundaryWithSentry>
        </>
    );
}

