import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import csSharedDictionary from 'src/shared/locales/cs.json';
import enSharedDictionary from 'src/shared/locales/en.json';
import skSharedDictionary from 'src/shared/locales/sk.json';
import huSharedDictionary from 'src/shared/locales/hu.json';
import deSharedDictionary from 'src/shared/locales/de.json';
import { Language } from 'src/shared-interface/ApiRestaurantAdminInterface';
import csDictionary from 'locales/cs.json';
import enDictionary from 'locales/en.json';
import skDictionary from 'locales/sk.json';
import deDictionary from 'locales/de.json';
import huDictionary from 'locales/hu.json';

import type { i18n as i18nInstance } from 'i18next';

const defaultResources = {
    [Language.cs]: {
        ...csSharedDictionary,
        ...csDictionary,
    },
    [Language.de]: {
        ...deSharedDictionary,
        ...deDictionary,
    },
    [Language.en]: {
        ...enSharedDictionary,
        ...enDictionary,
    },
    [Language.sk]: {
        ...skSharedDictionary,
        ...skDictionary,
    },
    [Language.hu]: {
        ...huSharedDictionary,
        ...huDictionary,
    },
} as const;

export const createI18nRestaurant = (language?: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);
    const resources = {
        [Language.cs]: defaultResources[Language.cs],
        [Language.en]: defaultResources[Language.en],
    };
    const fallbackLng = Language.en;

    if (language) {
        i18n.init({
            fallbackLng,
            lng: language,
            resources,
        });
    } else {
        i18n.use(LanguageDetector)
            .init({
                fallbackLng,
                resources,
            });
    }

    return i18n;
};
