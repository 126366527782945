import createCache from '@emotion/cache';

const isBrowser = typeof document !== 'undefined';

export function createEmotionCache() {
    let insertionPoint: HTMLElement | undefined = undefined;

    if (isBrowser) {
        const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]') as HTMLElement;
        insertionPoint = emotionInsertionPoint ?? undefined;
    }

    return createCache({ insertionPoint, key: 'mui-style' });
}
