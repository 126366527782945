import React from 'react';
import * as Sentry from '@sentry/nextjs';

export const ErrorBoundaryWithSentry: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => (
    <Sentry.ErrorBoundary
        fallback={(
            <div>
                <h2>Oops, there is an error!</h2>
            </div>
        )}
    >
        {children}
    </Sentry.ErrorBoundary>
);
